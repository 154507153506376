import { ReactNode } from "react";
import cx from "classnames";

import { useTransition } from "common/hooks";
import { stopPropagation } from "common/utils";
import { StackableProps } from "common/stackable/types";

import styles from "../../styles/Lightbox.module.less";

type LightboxProps = {
  children: ReactNode;
} & StackableProps;

const Lightbox = ({ isVisible, onClose, children }: LightboxProps) => {
  const { isMounted, transitionState, durationStyle } = useTransition(isVisible);

  if (!isMounted) {
    return null;
  }

  return (
    <div
      className={`${styles.container} ${styles[`transition-${transitionState}`]}`}
      style={{ transitionDuration: durationStyle }}
      role="presentation"
      onClick={onClose}
    >
      <div
        className={cx(styles.modal, styles[`transition-${transitionState}`])}
        style={{ transitionDuration: durationStyle }}
        role="presentation"
        onMouseDown={stopPropagation}
        onClick={stopPropagation}
      >
        {children}
      </div>
    </div>
  );
};

export default Lightbox;
