import ReactDOM from "react-dom";

type PortalProps = { children: React.ReactNode };

/**
 * @deprecated Replaced by Stackables
 * @see {@link stackables/hooks} for new implementation
 */
const Portal = (props: PortalProps) => {
  const { children } = props;

  return ReactDOM.createPortal(children, document.body);
};

export default Portal;
