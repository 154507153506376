import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

import { safeLoad } from "common/load/utils";

import styleVars from "client/common/styles/export-variables.less?export";

const key = window.STRIPE_API_KEY;
const stripePromise = safeLoad(() => loadStripe(key));
const fonts = [{ cssSrc: `${styleVars.fontUrl.replace(/["']/g, "")}.css` }];
const options = { fonts };

type StripeProviderProps = { children: React.ReactNode };

const StripeProvider = ({ children }: StripeProviderProps) => {
  return (
    // Add a key and keep params outside of the function to avoid rerenders
    <Elements key={key} stripe={stripePromise} options={options}>
      {children}
    </Elements>
  );
};

export default StripeProvider;
