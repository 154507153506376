import Container from "./Container";

import styles from "../../styles/Body.module.less";

type BodyProps = {
  children: React.ReactNode;
  className?: string;
};

const Body = ({ children, className = "" }: BodyProps) => {
  return (
    <div className={`${styles.main} ${className}`}>
      <Container>{children}</Container>
    </div>
  );
};

export default Body;
