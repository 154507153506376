import { ReactLocationDevtoolsPanel } from "@tanstack/react-location-devtools";
import { ReactQueryDevtoolsPanel } from "@tanstack/react-query-devtools";
import { ReactNode, useRef, useState } from "react";
// eslint-disable-next-line no-restricted-imports
import { useRouter } from "@tanstack/react-location";
import cx from "classnames";

import styles from "common/styles/components/DevTools.module.less";

const MIN_HEIGHT = 100;
const DEFAULT_HEIGHT = 400;

type PanelKind = "router" | "query";

type RenderTabContentProps = {
  isOpen: boolean;
  onChangeIsOpen: (isActive: boolean) => void;
};

type Tab = {
  label: string;
  panelKind: PanelKind;
  renderContent: (props: RenderTabContentProps) => ReactNode;
};

/**
 * Provides a UI to surface various devtools, this should be added to the react-tree with any
 * needed dev-tools appearing higher than this component.
 */
const DevTools = () => {
  const [activePannel, setActivePannel] = useState<PanelKind>("query");
  const [isOpen, setIsOpen] = useState(false);
  const [devToolsHeight, setDevtoolsHeight] = useState(DEFAULT_HEIGHT);
  const panelRef = useRef<HTMLDivElement>(null);

  /**
   * Handle the user dragging the top of the panel up or down.
   * Simplified logic from react-query dev tools
   * https://github.com/TanStack/query/blob/52764b0d461cbfe3acd7c0840c0477b834a9a0ac/packages/react-query-devtools/src/devtools.tsx#L161
   * */
  const handleDragStart = (startEvent: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const panel = panelRef.current;
    if (!panel) {
      return;
    }

    const { height } = panel.getBoundingClientRect();
    const startY = startEvent.clientY;
    let newSize = 0;

    const handleMouseMove = (moveEvent: MouseEvent) => {
      // prevent mouse selecting stuff with mouse drag
      moveEvent.preventDefault();
      newSize = height + startY - moveEvent.clientY;
      setDevtoolsHeight(Math.max(newSize, MIN_HEIGHT));
      if (newSize < MIN_HEIGHT) {
        setIsOpen(false);
      } else {
        setIsOpen(true);
      }
    };

    const stopHandlingMouseMove = () => {
      document.removeEventListener("mousemove", handleMouseMove, false);
      document.removeEventListener("mouseUp", stopHandlingMouseMove, false);
    };

    document.addEventListener("mousemove", handleMouseMove, false);
    document.addEventListener("mouseup", stopHandlingMouseMove, false);
  };

  const tabs: Tab[] = [
    {
      label: "Query",
      panelKind: "query",
      renderContent: ({
        isOpen: isActive,
        onChangeIsOpen: onChangeIsActive,
      }: RenderTabContentProps) => (
        <ReactQueryDevtoolsPanel
          isOpen={isActive}
          setIsOpen={onChangeIsActive}
          onDragStart={handleDragStart}
          style={{ height: devToolsHeight }}
        />
      ),
    },
    {
      label: "Router",
      panelKind: "router",
      // isOpen doesn't seem to actually hide the react location dev panel so
      // we have to handle conditional rendering ourselves
      renderContent: ({
        isOpen: isActive,
        onChangeIsOpen: onChangeIsActive,
      }: RenderTabContentProps) => (
        <ReactLocationDevtoolsPanel
          isOpen={isActive}
          setIsOpen={onChangeIsActive}
          handleDragStart={handleDragStart}
          useRouter={useRouter}
          style={{ height: devToolsHeight }}
        />
      ),
    },
  ];

  const activeTab = tabs.find((tab) => activePannel === tab.panelKind);

  if (!isOpen) {
    return (
      <button type="button" className={styles.openBtn} onClick={() => setIsOpen(true)}>
        Dev Tools
      </button>
    );
  }

  return (
    <div className={styles.panels} style={{ height: "auto" }}>
      <div className={styles.head}>
        <div className={styles.tabs}>
          {tabs.map((tab) => (
            <button
              type="button"
              className={cx(styles.tab, activeTab?.panelKind === tab.panelKind && styles.active)}
              key={tab.label}
              onClick={() => setActivePannel(tab.panelKind)}
            >
              {tab.label}
            </button>
          ))}
        </div>
        <button type="button" className={styles.closeBtn} onClick={() => setIsOpen(false)}>
          Close
        </button>
      </div>
      <div className={styles.content} ref={panelRef}>
        {activeTab?.renderContent({ isOpen, onChangeIsOpen: () => setIsOpen(false) })}
      </div>
    </div>
  );
};

export default process.env.NODE_ENV === "development" ? DevTools : () => null;
