import { ReactNode, useMemo } from "react";

import AuthenticationContext from "common/auth/AuthenticationContext";
import { normalizePathname } from "common/utils/urls";
import { useBuildNext, useLocation } from "common/router/hooks";

import { urls } from "client/routes";

import { getInitialClientData } from "../utils";

const { is_authenticated: isAuthenticated } = getInitialClientData();

type PublicContentProps = {
  children: ReactNode;
};

const ClientAuthenticationProvider = ({ children }: PublicContentProps) => {
  const location = useLocation();
  const buildNext = useBuildNext();
  const loginPathname = urls.accounts.login.to();
  const authValue = useMemo(() => {
    return {
      // we don't have to worry about this changing because we will always refresh on login
      // (and re-generate this value from the BE)
      isAuthenticated,
      onUnauthenticated: () => {
        if (normalizePathname(loginPathname) === normalizePathname(location.current.pathname)) {
          console.error(
            `Auth error encountered, not performing redirect to avoid infinite cycles
        for login url [${loginPathname}] and current page [${location.current.pathname}] `,
          );
          return;
        }
        // hard refresh on navigation to reset any errored queries
        window.location.replace(
          buildNext(
            urls.accounts.login({
              search: {
                next: location.current.href,
                flash: "Please log in to continue.",
              },
            }),
          ),
        );
      },
    };
  }, [buildNext, location, loginPathname]);
  return (
    <AuthenticationContext.Provider value={authValue}>{children}</AuthenticationContext.Provider>
  );
};

export default ClientAuthenticationProvider;
