import { faSpinnerThird, IconDefinition } from "@fortawesome/pro-regular-svg-icons";

import Icon from "./Icon";

import styles from "../styles/components/Spinner.module.less";

type SpinnerProps = { className?: string; icon?: IconDefinition };

const Spinner = ({ className = "", icon = faSpinnerThird }: SpinnerProps) => {
  return <Icon fixedWidth={true} icon={icon} className={`${styles.spinner} ${className}`} />;
};

export default Spinner;
