import { faMessages, faBlockQuestion, faEnvelope } from "@fortawesome/pro-light-svg-icons";
import { faArrowRight, faArrowLeft, IconDefinition } from "@fortawesome/pro-regular-svg-icons";
import cx from "classnames";

import { useAuthentication } from "common/auth/hooks";
import LinkButton, { LinkButtonProps } from "common/components/LinkButton";
import Icon from "common/components/Icon";
import Image from "common/components/Image";
import { VendorSlug } from "common/types/vendors";
import { toPhone } from "common/utils/formatting";

import { useClinic } from "client/clinics/hooks";
import { getInitialClientData } from "client/common/utils";
import { ModalStatus } from "client/common/types";
import { externalUrls, urls } from "client/routes";
import {
  useFulfillerInfoDrawer,
  usePrimaryFulfillerInfoDrawer,
  useShippingInfoDrawer,
} from "client/common/partners/stackables";
import { hasFulfillerInfoDrawer } from "client/common/partners/utils";
import { useSupportChat } from "client/support/hooks";

import Container from "../layouts/Container";
import nabp from "../../images/nabp.svg";
import Accordion from "../interactive/Accordion";
import TextButton from "../buttons/TextButton";

import styles from "../../styles/Footer.module.less";

const Footer = () => {
  const clinic = useClinic();
  const { isAuthenticated } = useAuthentication();
  const chat = useSupportChat();
  const {
    vetcove_support_email: supportEmail,
    vetcove_support_hours: supportHours,
    vetcove_support_phone: supportPhone,
  } = getInitialClientData();

  const { open: openShippingInfoDrawer } = useShippingInfoDrawer();
  const { open: openFulfillerInfoDrawer } = useFulfillerInfoDrawer();
  const { open: openPrimaryFulfillerInfoDrawer, hasDrawer: hasPrimaryFulfillerDrawer } =
    usePrimaryFulfillerInfoDrawer();

  const navigation: {
    label: string;
    links: (LinkButtonProps & { children: string })[];
    extra?: React.ReactNode;
  }[] = [
    {
      label: "Popular categories",
      links: clinic.footer_categories.map((category) => ({
        children: category.name,
        dest: urls.products.category({ params: { id: category.id } }),
      })),
      extra: [
        <LinkButton
          key="view-all"
          dest={urls.products.search()}
          className={cx(styles.item, styles.all)}
        >
          View all categories <Icon className={styles.angle} icon={faArrowRight} />
        </LinkButton>,
      ],
    },
    {
      label: "Storefront",
      links: [
        {
          children: "FAQs",
          dest: urls.support.helpCenter(),
        },
        {
          children: "Shipping policy",
          onClick: openShippingInfoDrawer,
        },
        ...(hasPrimaryFulfillerDrawer
          ? [
              {
                children: "Pharmacy partner",
                onClick: () => openPrimaryFulfillerInfoDrawer(),
              },
            ]
          : []),
        ...(hasFulfillerInfoDrawer(VendorSlug.Wedgewood)
          ? [
              {
                children: "Compounding partner",
                onClick: () => openFulfillerInfoDrawer({ slug: VendorSlug.Wedgewood }),
              },
            ]
          : []),
      ],
    },
    {
      label: "Account",
      links: [
        {
          children: "My account",
          dest: urls.accounts.detail(),
        },
        {
          children: "My prescriptions",
          dest: urls.prescriptions.list(),
        },
        {
          children: "My orders",
          dest: urls.orders.list(),
        },
        {
          children: "Track an order",
          dest: isAuthenticated
            ? urls.orders.list()
            : urls.support.helpCenter({ search: { orderStatusModalIsVisible: ModalStatus.Open } }),
        },
        // TODO: Enable once Mitch is ready
        // {
        //   label: 'Refill Prescription',
        //   to: urls.refills.requests.start(),
        // },
        ...(isAuthenticated
          ? [
              {
                children: "Sign out",
                href: externalUrls.signout(),
                newTab: false,
              },
            ]
          : [
              {
                children: "Log in",
                dest: urls.accounts.login(),
              },
              {
                children: "Sign up",
                dest: urls.accounts.signup(),
              },
            ]),
      ],
    },
  ];

  const helpLinks: (LinkButtonProps & { children: string; icon: IconDefinition })[] = [
    {
      dest: urls.support.helpCenter(),
      children: "FAQ",
      icon: faBlockQuestion,
    },
    {
      onClick: () => chat.show(),
      children: "Chat",
      icon: faMessages,
    },
    // {
    //   href: '/', // TODO: Get the clinic text phone number
    //   label: 'Text',
    //   icon: faMessageDots,
    // },
    {
      href: `mailto:${supportEmail}`,
      children: "Email",
      icon: faEnvelope,
    },
  ];

  const legalLinks: (LinkButtonProps & { children: string })[] = [
    {
      children: "Terms of use",
      dest: urls.support.termsOfUse(),
    },
    {
      children: "Privacy policy",
      dest: urls.support.privacyPolicy(),
    },
    {
      children: "Safe needle disposal",
      href: externalUrls.safeNeedleDisposal(),
    },
    {
      children: "Proper medication disposal",
      href: externalUrls.disposalOfUnusedMedicine(),
    },
  ];

  return (
    <div className={styles.main}>
      <Container>
        <div className={styles.container}>
          <div className={styles.content}>
            <div className={styles.navs}>
              <div className={styles.options}>
                {navigation.map((nav) => (
                  <div className={styles.nav} key={nav.label}>
                    <div className={styles.label}>{nav.label}</div>
                    <div className={styles.links}>
                      {nav.links.map(({ children, ...rest }) => (
                        <LinkButton {...rest} className={styles.item} key={children}>
                          {children}
                        </LinkButton>
                      ))}
                      {nav.extra}
                    </div>
                  </div>
                ))}
              </div>
              <div className={styles.accordion}>
                <Accordion.Single
                  classNames={{ label: styles.label, item: styles.noBorder }}
                  items={navigation.map((nav) => ({
                    label: nav.label,
                    key: nav.label,
                    content: (
                      <div className={styles.links}>
                        {nav.links.map(({ children, ...rest }) => (
                          <LinkButton {...rest} className={styles.item} key={children}>
                            {children}
                          </LinkButton>
                        ))}
                        {nav.extra}
                      </div>
                    ),
                  }))}
                />
              </div>
            </div>
            <div className={styles.help}>
              <div>
                <p className={styles.label}>We&apos;re here to help</p>
                <p className={styles.sublabel}>(With paw-some online store support)</p>
              </div>
              <div className={styles.links}>
                {helpLinks.map(({ icon, children, ...other }) => (
                  <LinkButton key={children} className={styles.link} {...other}>
                    <div className={styles.button}>
                      <Icon className={styles.icon} fixedWidth={true} icon={icon} />
                    </div>
                    <p className={styles.info}>{children}</p>
                  </LinkButton>
                ))}
              </div>
              <p className={styles.finePrint}>
                <div>
                  This website is powered by Vetcove on behalf of {clinic.display_name}. For
                  questions related to your order, contact Vetcove customer support (available{" "}
                  {supportHours}) at{" "}
                  <TextButton href={`tel:${supportPhone}`}>{toPhone(supportPhone)}</TextButton>.
                </div>
                <div>
                  Questions for your veterinarian? Please call{" "}
                  {clinic.phone_number ? (
                    <TextButton href={`tel:${clinic.phone_number}`}>
                      {toPhone(clinic.phone_number)}
                    </TextButton>
                  ) : (
                    "our offices"
                  )}
                  .
                </div>
              </p>
            </div>
          </div>
          <div className={styles.bottom}>
            <div className={styles.sites}>
              <div className={styles.site}>
                <LinkButton className={styles.button} href={clinic.website_url || "/"}>
                  <Icon className={styles.angle} icon={faArrowLeft} /> Return to main site
                </LinkButton>
              </div>
              <LinkButton href={externalUrls.nabp()} className={styles.nabp}>
                <Image src={nabp} alt="NABP" />
              </LinkButton>
            </div>
            <div className={styles.fine}>
              <div className={styles.legal}>
                {legalLinks.map(({ children, ...other }) => (
                  <LinkButton className={styles.legalLink} key={children} {...other}>
                    {children}
                  </LinkButton>
                ))}
              </div>
              <div className={styles.copyright}>
                <p className={styles.text}>
                  © {new Date().getFullYear()} Vetcove Inc. All rights reserved.
                </p>
              </div>
            </div>
            <p className={styles.disclaimer}>
              <p>
                This site is powered by Vetcove, a software company that provides technical tools on
                behalf of your veterinarian. Vetcove is accredited by the National Association of
                Boards of Pharmacy (NABP). Vetcove is not a pharmacy, does not engage in the
                practice of pharmacy, and does not provide any medical advice. In providing a
                detailed description of available medications, Vetcove does not warrant the accuracy
                of the content, nor does Vetcove express an opinion on the nature or propriety of
                the medications sought. The information provided on this site is for general
                informational purposes only and should not be relied upon as a substitute for
                professional veterinary advice. Questions regarding available medications or the
                health of your animal should be made directly to your veterinarian.
              </p>
              <p>
                A valid veterinarian-client-patient-relationship (&quot;VCPR&quot;) is required to
                purchase prescription medications. All requests for prescription medications
                submitted through this site will be reviewed by your veterinarian. Any orders placed
                for home delivery may be fulfilled by one of our pharmacy partners. Vetcove and its
                pharmacy partners will deliver your order on behalf of your veterinarian to your
                home. For more information regarding our pharmacy partners, please check out our
                FAQs or click on the &quot;Pharmacy Partner&quot; link above. Please note that you
                have the right to purchase and refill your medications from the pharmacy of your
                choice.
              </p>
              <p>
                Your credit card statement will reflect a charge from Vetcove for your purchase.
                Please reach out to Vetcove with any issues pertaining to your order.
              </p>
            </p>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Footer;
