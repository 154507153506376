import { Optional } from "common/utils/types";
import { smartSrc } from "common/utils/images";

import styles from "../styles/components/Image.module.less";

export type ImageProps = {
  src?: Optional<string>;
  alt: string;
  className?: string;
};

const Image = ({ src, alt, className = "" }: ImageProps) => {
  return <img className={`${styles.image} ${className}`} src={smartSrc(src)} alt={alt} />;
};

export default Image;
