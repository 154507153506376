// eslint-disable-next-line no-restricted-imports
import {
  Navigate as LibNavigate,
  NavigateOptions,
  PartialGenerics,
} from "@tanstack/react-location";
import { useEffect } from "react";

import { Destination, PathData, PluckDestination } from "./types";

const noop = () => undefined;

export type NavigateProps<TPath extends PartialGenerics = PathData> = PluckDestination<
  NavigateOptions<TPath>
> & {
  dest: Destination<TPath, TPath["Search"]>;
  onRedirect?: () => void;
};

/**
 * When rendered, navigates the user to `dest`
 *
 * Also defaults to replacing the current `URL` to match the common use
 * case of doing a cheap client-side redirect.
 *
 * @see [Navigate](https://react-location.tanstack.com/docs/api#navigate) for library documentation
 * */
const Navigate = <TPath extends PartialGenerics = PathData>({
  dest,
  onRedirect = noop,
  ...rest
}: NavigateProps<TPath>) => {
  useEffect(() => {
    onRedirect();
    // only want to trigger callback on mount because consumer
    // provided callbacks are prone to changing on each render
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return <LibNavigate<TPath> {...dest} replace={true} {...rest} />;
};

export default Navigate;
