type DangerousInnerHTMLProps = { Node?: "div" | "span"; html?: string; className?: string };

const DangerousInnerHTML = ({
  Node = "div",
  html = "",
  className = "",
}: DangerousInnerHTMLProps) => {
  /* Only use when we are certain that the HTML has been sanitized. Be careful not to expose to to XSS attacks! */
  /* More info at: https://reactjs.org/docs/dom-elements.html#dangerouslysetinnerhtml */
  /* eslint-disable-next-line react/no-danger */
  return <Node className={className} dangerouslySetInnerHTML={{ __html: html }} />;
};

export default DangerousInnerHTML;
