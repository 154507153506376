import "common/styles/normalize.less";
import "common/styles/resets.less";
import "common/styles/imports.less";

import GlobalErrorBoundary from "./common/components/errors/GlobalErrorBoundary";
import StripeProvider from "./common/contexts/StripeProvider";
import ClientLoadStatusProvider from "./common/load/ClientLoadStatusProvider";
import ClientRouter from "./routes/ClientRouter";
import ClientIntercomProvider from "./common/contexts/ClientIntercomProvder";

const App = () => {
  return (
    <GlobalErrorBoundary>
      <ClientLoadStatusProvider>
        <StripeProvider>
          <ClientIntercomProvider>
            <ClientRouter />
          </ClientIntercomProvider>
        </StripeProvider>
      </ClientLoadStatusProvider>
    </GlobalErrorBoundary>
  );
};

export default App;
