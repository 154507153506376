import { useRef, useState } from "react";
import { faArrowLeft, faArrowRight, faMagnifyingGlass } from "@fortawesome/pro-regular-svg-icons";
import cx from "classnames";

import { useTransition } from "common/hooks";
import { stopPropagation } from "common/utils";
import { useAuthentication } from "common/auth/hooks";
import LinkButton, { LinkButtonProps } from "common/components/LinkButton";
import Icon from "common/components/Icon";

import { useClinic } from "client/clinics/hooks";
import { useSearchContext } from "client/common/hooks";
import { externalUrls, urls } from "client/routes";
import {
  usePrimaryFulfillerInfoDrawer,
  useShippingInfoDrawer,
} from "client/common/partners/stackables";
import { useSupportChat } from "client/support/hooks";

import ModalPortal from "../deprecated-modals/ModalPortal";
import PillButton from "../buttons/PillButton";
import CloseButton from "../buttons/CloseButton";

import styles from "../../styles/MenuDrawer.module.less";

type MenuDrawerProps = {
  visible: boolean;
  onClose: () => void;
};

type MenuLink = LinkButtonProps & { children: string };

const MenuDrawer = ({ visible, onClose }: MenuDrawerProps) => {
  const { open: openPrimaryFulfillerInfoDrawer, hasDrawer: hasPrimaryFulfillerInfoDrawer } =
    usePrimaryFulfillerInfoDrawer();
  const { open: openShippingInfoDrawer } = useShippingInfoDrawer();

  const { focusSearchInput } = useSearchContext();
  const chat = useSupportChat();
  const clinic = useClinic();
  const { isAuthenticated } = useAuthentication();
  const { transitionState, isMounted } = useTransition(visible);

  const scrollRef = useRef<HTMLDivElement>(null);

  const [showTopScrollHelper, setShowTopScrollHelper] = useState(false);
  const [showBottomScrollHelper, setShowBottomScrollHelper] = useState(true);

  const handleKeyPress = (event: React.KeyboardEvent) => {
    if (event.key === "Escape") {
      onClose();
    }
  };

  const toggleScrollHelper = () => {
    const scrollElement = scrollRef.current;

    if (!scrollElement) {
      return;
    }

    // Show the helpers when we're not at that edge
    setShowTopScrollHelper(scrollElement.scrollTop > 0);
    setShowBottomScrollHelper(
      scrollElement.scrollHeight - scrollElement.clientHeight - scrollElement.scrollTop > 0,
    );
  };

  if (!isMounted) {
    return null;
  }

  const signedInLinks: MenuLink[] = [
    // TODO: Enable once Mitch is ready
    // {
    //   label: 'Reorder',
    //   to: refillUrls.getRootPage(),
    // },
    {
      children: "Browse products",
      dest: urls.products.search(),
    },
    {
      children: "My order status & history",
      dest: urls.orders.list(),
    },
    {
      children: "My pet's prescriptions",
      dest: urls.prescriptions.list(),
    },
    {
      children: "My autoships",
      dest: urls.subscriptions.list(),
    },
  ];

  const signedOutLinks: (MenuLink & { description: string })[] = [
    {
      children: "Browse products",
      description: "Browse our online store catalog",
      dest: urls.products.search(),
    },
    // TODO: Enable once Mitch is ready
    // {
    //   label: 'Fill an existing prescription',
    //   description: 'Fill an existing prescription on file',
    //   to: refillUrls.getRootPage(),
    // },
  ];

  const secondaryLinks: MenuLink[] = isAuthenticated
    ? [
        {
          children: "My Account",
          dest: urls.accounts.detail(),
        },
        {
          children: "FAQs",
          dest: urls.support.helpCenter(),
        },
        {
          children: "Shipping Policy",
          onClick: openShippingInfoDrawer,
        },
        ...(hasPrimaryFulfillerInfoDrawer
          ? [
              {
                children: "Pharmacy Partner",
                onClick: () => openPrimaryFulfillerInfoDrawer(),
              },
            ]
          : []),
        ...(clinic.website_url
          ? [
              {
                children: "Return to Clinic Website",
                href: clinic.website_url,
                newTab: false,
              },
            ]
          : []),
        {
          children: "Logout",
          href: externalUrls.signout(),
          newTab: false,
        },
      ]
    : [
        {
          children: "Sign in",
          dest: urls.accounts.login(),
        },
        {
          children: "Create account",
          dest: urls.accounts.signup(),
        },
        {
          children: "FAQs",
          dest: urls.support.helpCenter(),
        },
        {
          children: "Shipping Policy",
          onClick: openShippingInfoDrawer,
        },
        ...(hasPrimaryFulfillerInfoDrawer
          ? [
              {
                children: "Pharmacy Partner",
                onClick: () => openPrimaryFulfillerInfoDrawer(),
              },
            ]
          : []),
      ];

  const handleSupportClick = () => {
    chat.show();
    // Close this since chat will open up as full screen on mobile
    onClose();
  };

  return (
    <ModalPortal>
      <div
        className={cx(styles.container, styles[`container-${transitionState}`])}
        onClick={onClose}
        onKeyDown={handleKeyPress}
        tabIndex={0}
        role="button"
      >
        <div
          className={cx(styles.box, styles[`box-${transitionState}`])}
          onClick={stopPropagation}
          role="presentation"
        >
          <div className={styles.header}>
            <CloseButton onClick={onClose} className={styles.closeButton} />
            {!isAuthenticated && (
              <div className={styles.headerAuth}>
                <PillButton
                  size="xs"
                  variant="tertiary"
                  dest={urls.accounts.signup()}
                  onClick={onClose}
                >
                  Create Account
                </PillButton>
                <PillButton
                  size="xs"
                  variant="primary"
                  dest={urls.accounts.login()}
                  onClick={onClose}
                >
                  Sign in
                </PillButton>
              </div>
            )}
          </div>
          <div className={styles.searchbar}>
            {/* button pretending to look like an input */}
            <button type="button" className={cx(styles.search)} onClick={focusSearchInput}>
              <span className={styles.placeholder}>Find medications...</span>
              <Icon className={styles.icon} icon={faMagnifyingGlass} />
            </button>
          </div>
          <div className={styles.content}>
            <div className={styles.links} ref={scrollRef} onScroll={() => toggleScrollHelper()}>
              <div className={styles.main}>
                {isAuthenticated
                  ? signedInLinks.map((link) => (
                      <LinkButton
                        className={styles.link}
                        key={link.children}
                        onClick={onClose}
                        {...link}
                      >
                        {link.children}
                      </LinkButton>
                    ))
                  : signedOutLinks.map((link) => (
                      <LinkButton
                        className={styles.bigLink}
                        key={link.children}
                        onClick={onClose}
                        {...link}
                      >
                        <div className={styles.text}>
                          <div className={styles.label}>{link.children}</div>
                          <div className={styles.description}>{link.description}</div>
                        </div>
                        <Icon className={styles.icon} icon={faArrowRight} />
                      </LinkButton>
                    ))}
              </div>
              <div className={styles.secondary}>
                {secondaryLinks.map((link) => (
                  <LinkButton
                    className={styles.link}
                    key={link.children}
                    onClick={onClose}
                    {...link}
                  >
                    {link.children}
                  </LinkButton>
                ))}
              </div>
            </div>
            <div className={cx(styles.scrollTop, showTopScrollHelper && styles.active)} />
            <div className={cx(styles.scrollBottom, showBottomScrollHelper && styles.active)} />
          </div>
          <div className={styles.footer}>
            <LinkButton
              className={styles.link}
              onClick={onClose}
              {...(clinic.website_url
                ? { href: clinic.website_url, newTab: false }
                : { dest: urls.home.root() })}
            >
              <Icon className={styles.icon} icon={faArrowLeft} />
              Return to Homepage
            </LinkButton>

            <PillButton variant="tertiary" size="xs" onClick={handleSupportClick}>
              Help &amp; Support
            </PillButton>
          </div>
        </div>
      </div>
    </ModalPortal>
  );
};

export default MenuDrawer;
