import { memo, useEffect, useState } from "react";

import Router from "common/router/Router";
import { useIsTransitioning, useRouter, useSearchState } from "common/router/hooks";
import Outlet from "common/router/Outlet";
import QueryClientProvider from "common/queries/QueryClientProvider";
import errorImages from "common/constants/errorImages";
import DevTools from "common/devtools/DevTools";
import StackableProvider from "common/stackable/StackableProvider";

import { useClientRoutes } from "client/routes";
import LoadingBar from "client/common/components/loading/LoadingBar";
import Error404Page from "client/common/components/errors/Error404Page";
import CartProvider from "client/carts/context/CartProvider";
import ErrorPage from "client/common/components/errors/ErrorPage";
import SearchProvider from "client/common/contexts/SearchProvider";
import ClientAuthenticationProvider from "client/common/contexts/ClientAuthenticationProvider";
import ClientFetchProvider from "client/common/contexts/ClientFetchProvider";
import EmptyPage from "client/common/components/layouts/EmptyPage";
import ClientAnalyticsProvider from "client/common/contexts/ClientAnalyticsProvider";

/** Query Params used on the root to help share state */
type AutoAuthSearch = Partial<{
  auto_auth_token: string;
}>;

const Main = () => {
  const router = useRouter();
  const [search, setSearch] = useSearchState<{ Search: AutoAuthSearch }>({});
  const isTransitioning = useIsTransitioning();
  const [wasTransitioning, setWasTransitioning] = useState<boolean>();

  // if we switch between pages during in-app navigation, scroll to the top after navigation.
  // Keep track of if we are coming from a transition to not do this on the first page load.
  useEffect(() => {
    if (wasTransitioning && !isTransitioning) {
      document.documentElement.scrollTop = 0;
    }
    setWasTransitioning(isTransitioning);
  }, [wasTransitioning, isTransitioning]);

  useEffect(() => {
    if (search.auto_auth_token) {
      setSearch({ ...search, auto_auth_token: undefined });
    }
  }, [search, setSearch]);

  return (
    <ClientAuthenticationProvider>
      <ClientAnalyticsProvider>
        <ClientFetchProvider>
          <QueryClientProvider>
            <StackableProvider>
              <CartProvider>
                <SearchProvider>
                  <div>
                    {router.pending && <LoadingBar />}
                    <Outlet />
                  </div>
                  <DevTools />
                </SearchProvider>
              </CartProvider>
            </StackableProvider>
          </QueryClientProvider>
        </ClientFetchProvider>
      </ClientAnalyticsProvider>
    </ClientAuthenticationProvider>
  );
};

const RouteErrorPage = () => {
  const router = useRouter();
  return (
    <div>
      {router.pending && <LoadingBar />}
      <ErrorPage
        image={errorImages.Error500}
        imageAlt="Internal Error"
        title="Oops!"
        description="The server encountered an internal error or misconfiguration and was unable to complete your request. Please try again later."
        // we need an empty page because in the case that the router fails, we won't have a query-client to display the appropriate
        // header content. This is due to the query client depending on the authenticaiton provider, which in-turn depends on the router
        // for navigation
        PageComponent={EmptyPage}
      />
    </div>
  );
};

const RouteNotFoundPage = () => {
  return <Error404Page />;
};

const ClientRouter = memo(() => {
  const routes = useClientRoutes({ MainComponent: Main, NotFoundComponent: RouteNotFoundPage });

  return <Router routes={routes} ErrorComponent={RouteErrorPage} />;
});

export default ClientRouter;
