import { createContext } from "react";

export type AuthenticationContextValue = {
  /** isAuthenticated will be true if the user is logged in. This does not imply
   * authorization (access to particular resources)
   */
  isAuthenticated: boolean;
  /**
   * handler that should be triggered if we hit a 401 error. An app can provide
   * a default implementation by implementing <AuthenticationContext.Provider>
   * or can create special contexts to handle errors differently for certain sub-trees
   * (eg. pharmacy client cart stepper)
   */
  onUnauthenticated: (err: Error) => void;
};

/**
 * General authentication information for an app (or sub-app) along with a callback
 * for when we detect that the user is no longer authenticated.
 *
 * Every app should ensure that a provider for this context wraps in react-tree
 * where {@link fetch/hooks!useFetch} could be called.
 *
 * @see {@link auth/hooks!useAuthentication} for how to consume this state in general application code.
 */
const AuthenticationContext = createContext<AuthenticationContextValue>({
  onUnauthenticated: () => console.warn("onUnauthorized is not bound"),
  isAuthenticated: false,
});

export default AuthenticationContext;
