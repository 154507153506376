import errorImages from "common/constants/errorImages";

import ErrorPage from "./ErrorPage";

type Error404PageProps = {
  title?: string;
  description?: string;
  buttonText?: string;
  buttonLink?: string;
};

const Error404Page = (props: Error404PageProps) => {
  const {
    title = "Oops!",
    description = "We can't seem to find the page you've been looking for.",
    buttonText,
    buttonLink,
  } = props;

  return (
    <ErrorPage
      image={errorImages.Error404}
      imageAlt="Not Found"
      title={title}
      description={description}
      buttonText={buttonText}
      buttonLink={buttonLink}
    />
  );
};

export default Error404Page;
