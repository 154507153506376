import posthog from "posthog-js";

import { IItemWithProduct } from "common/types";
import { toDollarValue } from "common/utils/formatting";
import { ICart } from "common/types/carts";
import { Optional } from "common/utils/types";

import { ClientListOrderItemItem, IOrder } from "client/orders/types";
import {
  IProductItem,
  IProductLineDetails,
  IProductLineProduct,
  ProductSearchResult,
} from "client/products/types";
import type { ProductLineForCard } from "client/products/components/ProductLineCard";
import { getInitialClientData } from "client/common/utils";
import { SubscriptionListItem } from "client/subscriptions/types";

import {
  EcommerceAddToCartEvent,
  EcommerceBeginCheckoutEvent,
  EcommerceEventName,
  EcommerceEventType,
  EcommerceItem,
  EcommerceItemWithQuantity,
  EcommerceSelectItemEvent,
  EcommerceViewItemEvent,
  EcommerceViewItemListEvent,
  EcommercePurchaseEvent,
  EcommerceRemoveFromCartEvent,
} from "../types";

const getEcommerceItemFromItem = (
  item: IItemWithProduct | ClientListOrderItemItem | SubscriptionListItem,
  { price, discount = 0 }: { price?: number; discount?: number } = {},
): EcommerceItem => ({
  item_id: `${item.id}`,
  item_name: `${item.product.product_line.name} ${item.product.name}`,
  item_brand: item.product.product_line.name,
  item_category:
    item.product.product_line.categories.length > 0
      ? item.product.product_line.categories[0].name
      : "Unknown",
  item_variant: `${item.product.name} · ${item.name}`,
  price: price ? toDollarValue(price) : toDollarValue(item.msrp),
  discount: toDollarValue(discount),
});

const getEcommerceItemFromProductSearchResult = (result: ProductSearchResult): EcommerceItem => ({
  item_name: result.full_name,
  item_brand: result.product_line.name,
  item_category: result.product_line.primary_category_name,
});

const getEcommerceItemFromProductLineCard = (productLine: ProductLineForCard): EcommerceItem => ({
  item_id: `pl_${productLine.id}`,
  item_brand: productLine.name,
});

type CartItemForAnalytics = {
  custom_unit_price: Optional<number>;
  default_unit_price: number;
  unit_discount: number;
  quantity: number;
};

type CartItemWithItem = CartItemForAnalytics & {
  item: IItemWithProduct;
};

export const getCartItemPricing = (cartItem: CartItemForAnalytics) => {
  const price = cartItem.custom_unit_price ?? cartItem.default_unit_price;
  const discount = cartItem.unit_discount;
  return { price, discount };
};

const getEcommerceItemFromCartItem = (
  cartItem: CartItemWithItem,
  { quantity }: { quantity: number },
): EcommerceItemWithQuantity => {
  return { ...getEcommerceItemFromItem(cartItem.item, getCartItemPricing(cartItem)), quantity };
};

/**
 * Sends data to our external analytics providers. Currently assumes that we are using Google Analytics type events
 * because we are not quite sure what the product team will need RE: custom fields / data until they get their hands in there.
 *
 * If we find out that the data we are sending to GTM is not the data that we need to send to posthog, etc. (a likely scenario),
 * we should consider breaking out the posthog sending from this function and creating a separate function for posthog. This implementation
 * would allow consumers to map the data for both send functions seperately, or not call the send function for one of the providers.
 *
 * Event names and data sent are defined by partners Google Tag Manager setup.
 * Ensure these names are kept consistent with the documentation we've shared with them here:
 * https://docs.google.com/document/d/1sYGNv_llIEIvoFTq2Z9fX12oGn3BT46NgAlDYM0LsI0/
 * */
export const sendEvent = <T extends EcommerceEventType<EcommerceEventName, object>>({
  eventName,
  data,
}: T) => {
  /**
   * If gtag is not defined, it means that the Google Analytics script in google_analytics_head.html
   *  has not been loaded. Likely because the clinic has no gtm_container_ids set.
   */
  if (window.gtag !== undefined) {
    gtag("event", eventName, data);
  }
  if (getInitialClientData().has_internal_analytics_tracking) {
    posthog.capture(eventName, data);
  }
};

export const sendPurchase = (order: IOrder, cart: ICart) => {
  const data: EcommercePurchaseEvent = {
    eventName: EcommerceEventName.Purchase,
    data: {
      currency: "USD",
      transaction_id: `${order.id}`,
      value: toDollarValue(order.discounted_subtotal),
      shipping: toDollarValue(order.shipping),
      tax: toDollarValue(order.tax),
      items: order.order_items.map((orderItem) => ({
        ...getEcommerceItemFromItem(orderItem.item, {
          price: orderItem.unit_price,
          discount: orderItem.amortized_discount,
        }),
        quantity: orderItem.quantity,
      })),
      coupon: cart.promo_codes.map((pc) => pc.code).join(", "),
    },
  };
  sendEvent(data);
};

export const sendAddToCart = (cartItem: CartItemWithItem, { quantity }: { quantity: number }) => {
  const data: EcommerceAddToCartEvent = {
    eventName: EcommerceEventName.AddToCart,
    data: {
      items: [getEcommerceItemFromCartItem(cartItem, { quantity })],
    },
  };
  sendEvent(data);
};

export const sendRemoveFromCart = (
  cartItem: CartItemWithItem,
  { quantity }: { quantity: number },
) => {
  const data: EcommerceRemoveFromCartEvent = {
    eventName: EcommerceEventName.RemoveFromCart,
    data: {
      items: [getEcommerceItemFromCartItem(cartItem, { quantity })],
    },
  };
  sendEvent(data);
};

export const sendViewItem = (
  productLine: IProductLineDetails,
  product: IProductLineProduct,
  item: IProductItem,
) => {
  const data: EcommerceViewItemEvent = {
    eventName: EcommerceEventName.ViewItem,
    data: {
      items: [
        {
          item_id: `${item.id}`,
          item_name: `${productLine.name} ${product.name}`,
          item_brand: productLine.name,
          item_category: productLine.primary_category_name,
          item_variant: `${product.label} · ${item.label}`,
          price: toDollarValue(item.price),
        },
      ],
    },
  };
  sendEvent(data);
};

export const sendViewItemList = (productLines: ProductLineForCard[], eventViewName: string) => {
  const data: EcommerceViewItemListEvent = {
    eventName: EcommerceEventName.ViewItemList,
    data: {
      item_list_name: eventViewName,
      items: productLines.map(getEcommerceItemFromProductLineCard),
    },
  };
  sendEvent(data);
};

export const sendViewSearchResultList = (results: ProductSearchResult[]) => {
  const data: EcommerceViewItemListEvent = {
    eventName: EcommerceEventName.ViewItemList,
    data: {
      item_list_name: "Search Results",
      items: results.map(getEcommerceItemFromProductSearchResult),
    },
  };
  sendEvent(data);
};

export const sendSelectItem = (
  item: IItemWithProduct | ClientListOrderItemItem | SubscriptionListItem,
  eventViewName: string,
  price?: number,
) => {
  const data: EcommerceSelectItemEvent = {
    eventName: EcommerceEventName.SelectItem,
    data: {
      item_list_name: eventViewName,
      items: [getEcommerceItemFromItem(item, { price })],
    },
  };
  sendEvent(data);
};

export const sendSearchResultClick = (result: ProductSearchResult) => {
  const data: EcommerceSelectItemEvent = {
    eventName: EcommerceEventName.SelectItem,
    data: {
      item_list_name: "Search Results",
      items: [getEcommerceItemFromProductSearchResult(result)],
    },
  };
  sendEvent(data);
};

export const sendProductLineCardClick = (
  productLine: ProductLineForCard,
  eventViewName: string,
) => {
  const data: EcommerceSelectItemEvent = {
    eventName: EcommerceEventName.SelectItem,
    data: {
      item_list_name: eventViewName,
      items: [getEcommerceItemFromProductLineCard(productLine)],
    },
  };
  sendEvent(data);
};

export const sendBeginCheckout = (cart: ICart) => {
  const data: EcommerceBeginCheckoutEvent = {
    eventName: EcommerceEventName.BeginCheckout,
    data: {
      items: cart.items.map((cartItem) =>
        getEcommerceItemFromCartItem(cartItem, { quantity: cartItem.quantity }),
      ),
    },
  };
  sendEvent(data);
};
