import { useState } from "react";
import cx from "classnames";

import { toggleValue } from "common/utils/sets";
import Angle from "common/components/Angle";
import Collapse from "common/components/Collapse";
import Spinner from "common/components/Spinner";

import styles from "../../styles/Accordion.module.less";

type Key = string | number;

type AccordionItem = {
  key: Key;
  label: React.ReactNode;
  content: React.ReactNode;
  description?: React.ReactNode;
  isLoading?: boolean;
};

type CommonAccordionProps = {
  classNames?: {
    container?: string;
    item?: string;
    header?: string;
    text?: string;
    label?: string;
    icon?: string;
    description?: string;
    content?: string;
  };
  items: AccordionItem[];
};

type BaseAccordionProps = CommonAccordionProps & {
  getIsOpen: (item: AccordionItem) => boolean;
  onSelect: (item: AccordionItem) => void;
};

const BaseAccordion = (props: BaseAccordionProps) => {
  const { items, classNames = {}, getIsOpen, onSelect } = props;

  return (
    <div className={cx(styles.container, classNames.container)}>
      {items.map((item) => {
        const isOpened = getIsOpen(item);

        return (
          <div key={item.key} className={cx(styles.item, classNames.item)}>
            <button
              type="button"
              className={cx(styles.header, classNames.header)}
              onClick={() => onSelect(item)}
            >
              <div className={cx(styles.text, classNames.text)}>
                <p className={cx(styles.label, classNames.label)}>{item.label}</p>
                {item.description && (
                  <p className={cx(styles.description, classNames.description)}>
                    {item.description}
                  </p>
                )}
              </div>
              {item.isLoading ? (
                <Spinner className={cx(styles.icon, classNames.icon)} />
              ) : (
                <Angle
                  className={cx(styles.icon, classNames.icon)}
                  direction="down"
                  isActive={isOpened}
                />
              )}
            </button>
            <Collapse isOpened={isOpened}>
              <div className={cx(classNames.content, styles.itemContent)}>{item.content}</div>
            </Collapse>
          </div>
        );
      })}
    </div>
  );
};

type SingleAccordionProps = CommonAccordionProps & {
  initialOpenKey?: Key;
};

const SingleAccordion = ({ initialOpenKey, ...other }: SingleAccordionProps) => {
  const [openKey, setOpenKey] = useState(initialOpenKey);

  return (
    <BaseAccordion
      {...other}
      getIsOpen={(item) => item.key === openKey}
      onSelect={(item) => setOpenKey((key) => (key === item.key ? "" : item.key))}
    />
  );
};

type MultiAccordionProps = CommonAccordionProps & {
  initialOpenKeys?: Key[];
};

const MultiAccordion = ({ initialOpenKeys, ...other }: MultiAccordionProps) => {
  const [openKeys, setOpenKeys] = useState(() => new Set(initialOpenKeys));

  return (
    <BaseAccordion
      {...other}
      getIsOpen={(item) => openKeys.has(item.key)}
      onSelect={(item) => setOpenKeys((keys) => toggleValue(keys, item.key))}
    />
  );
};

export default Object.assign(BaseAccordion, { Single: SingleAccordion, Multi: MultiAccordion });
