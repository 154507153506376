import { ReactNode, useEffect } from "react";
import posthog from "posthog-js";
import { PostHogProvider } from "posthog-js/react";

import { useLocation } from "common/router/hooks";
import { toPhone } from "common/utils/formatting";
import { BasicAuthUser } from "common/auth/types";

/**
 * Prefix to make ids globally unique across vetcove apps to consistently check if a user has been identified or not
 * See docs on aliases for how to possibly combine this with the user's over-aching vetcove id, etc.
 * https://posthog.com/docs/product-analytics/identify#alias-assigning-multiple-distinct-ids-to-the-same-user
 */
const PHARMACY_ID_PREFIX = "hd-user-";

type AnalyticsProviderProps = {
  children: ReactNode;
  /** The user, if logged-in */
  user: BasicAuthUser | null;
  hasSessionRecording: boolean;
  hasTracking: boolean;
};

/**
 * A top-level provider to hold logic related to product analytics.
 * Right now we are pulling in our analytics (currently GTM for clinic-facing analytics, and Posthog for internal analytics).
 *
 * It would be nice to lean fully on the posthog provider and not need to call init ourselves here, though
 * we'd need to re-work the way we are currently triggering events. We would need to update our
 * event tracking to use hooks again to support this.
 * @see https://posthog.com/docs/libraries/react
 */
const AnalyticsProvider = ({
  children,
  user,
  hasSessionRecording,
  hasTracking,
}: AnalyticsProviderProps) => {
  // because we have a single page app, we need to manually trigger page view events
  const location = useLocation();

  // initialize posthog on mount
  useEffect(() => {
    if (!window.POSTHOG_PUBLIC_API_KEY) {
      return;
    }

    posthog.init(window.POSTHOG_PUBLIC_API_KEY, {
      // we will manually trigger page view events because we have FE routing.
      // this also ensures that pageview events work even if auto-capture is disabled.
      capture_pageview: false,
      // session recording variables coming in soon, but let's start with it disabled to make it so that we can comfortably
      // test things locally without worrying about it getting enabled globally
      disable_session_recording: !hasSessionRecording,
      loaded: () => {
        // Identify the current user, this assumes that the page is refreshed on login/logout
        // this will tie all events to a single user, even if they were previously anonymous
        if (user) {
          posthog.identify(`${PHARMACY_ID_PREFIX}${user.id}`, {
            email: user.email,
            phone_number: toPhone(user.phone_number),
            first_name: user.first_name,
            last_name: user.last_name,
          });
        } else if (posthog.get_distinct_id().startsWith(PHARMACY_ID_PREFIX)) {
          // if the user is already identified, this means the user logged out on a previous page load, so we need to reset
          // if the user ever logs back-in, this session should get linked to that new logged-in session
          posthog.reset();
        }
      },
    });

    if (hasTracking) {
      posthog.capture("$pageview"); // let's capture the pageview for the initial page load
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // track page views on navigation
  useEffect(() => {
    if (!hasTracking) {
      return undefined;
    }

    const stopListening = location.history.listen(() => {
      posthog.capture("$pageview");
    });
    return () => stopListening();
  }, [location.history, hasTracking]);

  return <PostHogProvider client={posthog}>{children}</PostHogProvider>;
};

export default AnalyticsProvider;
