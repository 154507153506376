import { createContext } from "react";

type CurrentStackableContextValue = {
  /** The type of the stackable */
  type: string | null;
  /** If the current stackable is visible or not */
  isVisible: boolean;
  /** If the current stackable is positioned after animating in */
  isPositioned: boolean;
  /** Removes the current stackable making it not visible */
  remove: () => void;
};

const CurrentStackableContext = createContext<CurrentStackableContextValue>({
  remove: () => console.warn("Oh no! CurrentStackableContext.remove() is not bound"),
  isVisible: false,
  type: null,
  isPositioned: false,
});

export default CurrentStackableContext;
