import { createContext, useContext } from "react";

type CartContextValue = {
  isCartPrebuilt: boolean;
  isCartMutationSuccess: boolean;
};

const CartContext = createContext<CartContextValue>({
  isCartPrebuilt: false,
  isCartMutationSuccess: false,
});

export const useCartContext = () => {
  return useContext(CartContext);
};

export default CartContext;
