import { ReactNode, useRef } from "react";
import cx from "classnames";

import { Transition, useFocusTrap, useNoBodyScroll } from "common/hooks";

import styles from "../../styles/Overlay.module.less";

type OverlayProps = {
  children: ReactNode;
  isFocused: boolean;
  isVisible: boolean;
  transition: Transition;
  onClose: () => void;
  className?: string;
};

const Overlay = ({
  children,
  isVisible,
  isFocused,
  transition,
  onClose,
  className,
}: OverlayProps) => {
  const ref = useRef<HTMLDivElement>(null);

  useNoBodyScroll();
  useFocusTrap({ ref, isFocused });

  const handleKeyPress = (event: React.KeyboardEvent) => {
    if (event.key === "Escape") {
      onClose();
    }
  };

  const handleMouseDown = (event: React.MouseEvent) => {
    if (event.target === event.currentTarget) {
      onClose();
    }
  };

  return (
    <div
      className={cx(
        styles.overlay,
        styles[`transition-${transition.transitionState}`],
        isVisible && styles.visible,
        className,
      )}
      style={{ transitionDuration: transition.durationStyle }}
      onMouseDown={handleMouseDown}
      onKeyDown={handleKeyPress}
      tabIndex={0}
      role="button"
      ref={ref}
    >
      {children}
    </div>
  );
};

export default Overlay;
