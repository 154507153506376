import { CardPaymentMethod, PaymentProvider, WalletType } from "common/types";
import { Optional } from "common/utils/types";

import noImage from "../images/no-image.svg";
import mastercard from "../images/payment-providers/mastercard.svg";
import visa from "../images/payment-providers/visa.svg";
import amex from "../images/payment-providers/amex.svg";
import discover from "../images/payment-providers/discover.svg";
import applepay from "../images/payment-providers/applepay.svg";
import googlepay from "../images/payment-providers/googlepay.svg";

const pharmacyProductionBucket = "pharmacy-production";
const vetcoveIntegrationProductionBucket = "vetcove-integration-production";

/** Bucket Suffixes that should be replaced with the production bucket when developing in non-production environments */
const nonProductionS3BucketReplacements = [
  ["pharmacy-staging", pharmacyProductionBucket],
  ["pharmacy-demo", pharmacyProductionBucket],
  ["pharmacy-pr-test", pharmacyProductionBucket],
  ["pharmacy-sandbox", pharmacyProductionBucket],
  // Useful to display images provided by BAM
  ["vetcove-staging", vetcoveIntegrationProductionBucket],
];

/**
 * Fallback to a default image if none is set.
 * Replace non production images with ones from the production bucket across various environments if
 * it was not built as part of the build process.
 *
 * Should track logic for BE function `image_smart_src` at common.utils.media_utils
 *
 * Note that some newly uploaded images may not render during local dev, as they'll only exist
 * in staging but this will convert those urls to production
 */
export const smartSrc = (src: Optional<string>) => {
  const srcToDisplay = src || noImage;

  if (srcToDisplay.includes("static/dist/assets")) {
    return srcToDisplay;
  }

  return nonProductionS3BucketReplacements.reduce((replacedSrc, [bucket, replacement]) => {
    return replacedSrc.replace(bucket, replacement);
  }, srcToDisplay);
};

export const getPaymentProviderImageSrc = (method: CardPaymentMethod) => {
  const provider = method.wallet ? method.wallet : method.brand;

  switch (provider) {
    case PaymentProvider.MASTERCARD:
      return mastercard;
    case PaymentProvider.VISA:
      return visa;
    case PaymentProvider.AMEX:
      return amex;
    case PaymentProvider.DISCOVER:
      return discover;
    case WalletType.APPLE:
      return applepay;
    case WalletType.GOOGLE:
      return googlepay;
    default:
      return undefined;
  }
};
