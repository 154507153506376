import { filterArrayForValidValues } from "./arrays";
import { Optional } from "./types";

/**
 * String or the parameterized type.
 * Using this as a return value requires casting because ts can't infer
 * conditional types on type parameters
 * github issue here: https://github.com/microsoft/TypeScript/issues/24929
 * @typeParam T the type in question
 * */
export type StringOrPassthrough<T> = T extends string ? string : T;

/** Given a string, capitalizes the first letter and sets every other letter
 * to lower case.
 * @typeParam S the type of the value being operated on */
export const capitalized = <S extends Optional<string>>(str: S): StringOrPassthrough<S> => {
  return (
    str && str.length ? [str[0].toUpperCase(), str.slice(1).toLowerCase()].join("") : str
  ) as any;
};

/**
 * Given a string, capitalizes each word in the string
 * Any existing whitespace is always replaced with a standard space.
 * @typeParam S the type of the value being operated on
 * */
export const titleCased = <S extends Optional<string>>(str: S): StringOrPassthrough<S> => {
  return (
    str && str.length
      ? str
          .split(/\s+/)
          .filter((s) => !!s)
          .map(capitalized)
          .join(" ")
      : str
  ) as any;
};

/** Given an amount, formats to singular or plural. Defaults to appending an
 * s to the singular value.
 * @typeParam S the type of the value being operated on.
 * @param n the count of items for the word to be pluralized.
 * @param singular the singular form of the word.
 * @param plural the plural form of the word, assumes we can just add an "s" by default.
 * */
export const pluralized = <S extends Optional<string>>(
  n: number,
  singular: S,
  plural?: Optional<string>,
): StringOrPassthrough<S> => {
  if (typeof singular !== "string" || n === 1) {
    return singular as any;
  }
  return (plural ?? (singular ? `${singular}s` : singular)) as any;
};

/** Crops the given string `str` to at most `limit` visible characters,
 * appending an ellipsis if text was removed.
 * @typeParam S the type of the value being operated on
 * @param str the string value we want to crop.
 * @param limit the maximum amount of visible characters.
 * */
export const cropped = <S extends Optional<string>>(
  str: S,
  limit: number,
): StringOrPassthrough<S> => {
  if (typeof str !== "string") {
    return str as any;
  }
  const trimmed = str.trim();
  if (!trimmed.length || trimmed.length <= limit || limit < 0) {
    return trimmed as any;
  }
  const limited = trimmed.slice(0, limit).trimEnd();
  return (limited.length ? `${limited}...` : limited) as any;
};

/** Returns whether a string is composed of entirely whitespace
 * @param s the string to check
 */
export const isWhitespace = (s: string) => /^\s+$/.test(s);

/** Returns whether a string contains a given search value */
export const isHit = (q: string, val: Optional<string>) =>
  !!val && val.toLocaleLowerCase().includes(q.toLocaleLowerCase());

export type InsertStringOptions = {
  /** The string to insert content into. */
  current: string;
  /** The string to insert. */
  insert: string;
  /**
   * The index to start insert (inclusive), defaults to the end
   * of the body.
   * */
  start?: number;
  /**
   * The index to end insert (exclusive), all characters between
   * `start` and `end` will be replaced, defaults to start,
   *  meaning no characters will be replaced.
   * */
  end?: number;
  /**
   * Whether a space should be added before inserting the
   * content, defaults to True, unless we're appending at the start of the string.
   * */
  shouldPrependSpace?: boolean;
  /**
   * Whether a space should be added after inserting the content,
   * defaults to True, unless we're appending at the end of the string.
   * */
  shouldAppendSpace?: boolean;
};

export type StringInsertionResult = {
  /** The final string after insertion */
  value: string;
  /** The string that was inserted */
  inserted: string;
};

/**
 * Inserts a string into another string.
 * @param options Any options to apply that should affect the string insertion
 */
export const insertString = (options: InsertStringOptions): StringInsertionResult => {
  const {
    current,
    insert,
    start = current.length,
    end = start,
    shouldPrependSpace = start !== 0,
    shouldAppendSpace = start !== current.length && end !== current.length,
  } = options;

  const { length } = current;
  if (!length) {
    return { value: insert, inserted: insert };
  }

  if (start < 0 || end < 0 || start > end || end > length) {
    console.warn(
      `Expected indices to be positive and start [${start}] <= end [${end}] <= template length [${length}]`,
    );
    return { value: current, inserted: "" };
  }

  const before = current.slice(0, start);
  const toInsert = `${shouldPrependSpace ? " " : ""}${insert}${shouldAppendSpace ? " " : ""}`;
  const after = current.slice(end);
  return { value: `${before}${toInsert}${after}`, inserted: toInsert };
};

/**
 * Checks if a given string is valid based on some set of valid options,
 * returning a fallback if not. Useful for enforcing enum types from strings.
 *
 * @typeParam S the type of the value being operated on
 * @param str The string to test
 * @param validValues The set of valid options to allow
 * @param fallback The value to fall back to if str isn't valid
 * @returns str if valid, or the fallback value if not
 */
export const stringToValidValue = <S extends string, D = S>(
  str: any,
  validValues: Iterable<S>,
  fallback: D,
): S | D => {
  const validSet = new Set(validValues);
  return validSet.has(str as S) ? (str as S) : fallback;
};

/**
 * Checks if the provided string can be split into an array of valid options.
 * No fallback values are used, invalid values will simply be omitted from the return.
 *
 * @typeParam S the type of the value being operated on
 * @param str The string to split and test
 * @param validValues The set of valid options to allow
 * @param delimiter The character or string to split the string by. Defaults to ','
 * @returns An array of valid values
 */
export const splitStringToValidValues = <S extends string>(
  str: Optional<string>,
  validValues: Iterable<S>,
  delimiter: string = ",",
): S[] => {
  if (!str) {
    return [];
  }
  return filterArrayForValidValues(str.split(delimiter), validValues);
};

/**
 * Ensures the provided parameter is of the specified generic type.
 *
 * @typeParam T they type to ensure `value` is converted to
 * @param value The string or generic type value to check
 * @param convertFn The function used to convert a string value to the generic type
 * @returns A value of the specified type
 */
export const convertStringPassthrough = <T>(value: string | T, convertFn: (str: string) => T): T =>
  typeof value === "string" ? convertFn(value) : value;

/**
 * Creates a string from a list using proper punctuation and "and"s.
 *
 * @param strings the string values we want to combine
 * @returns The human readable list as a string
 */
export const toHumanizedList = (strings: Iterable<string>) => {
  const stringsList = Array.from(strings);
  if (!stringsList.length) {
    return "";
  }

  const lastSeparator =
    {
      1: "",
      2: " and ",
    }[stringsList.length] || ", and ";

  return stringsList.reduce((acc, string, index) => {
    const separator = index === stringsList.length - 1 ? lastSeparator : ", ";
    return `${acc}${separator}${string}`;
  });
};

export const toPossessive = (name: string) => {
  return name.endsWith("s") ? `${name}'` : `${name}'s`;
};

/**
 * Excape a string for use in a regular expression.
 *
 * @see https://github.com/sindresorhus/escape-string-regexp/blob/main/index.js
 */
export const escapeForRegExp = (string: string) => {
  return string.replace(/[|\\{}()[\]^$+*?.]/g, "\\$&").replace(/-/g, "\\x2d");
};
