import { faTimes } from "@fortawesome/pro-regular-svg-icons";

import LinkButton, { LinkButtonProps } from "common/components/LinkButton";
import Icon from "common/components/Icon";

import styles from "../../styles/CloseButton.module.less";

type CloseButtonProps = { children?: never } & LinkButtonProps;

const CloseButton = (props: CloseButtonProps) => {
  const { className = "", ...other } = props;

  const classNames = `${styles.close} ${className}`;

  return (
    <LinkButton className={classNames} {...other}>
      <Icon icon={faTimes} />
    </LinkButton>
  );
};

export default CloseButton;
