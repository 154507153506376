import { toOrdinal as toNumberOrdinal } from "./formatting";
import { Optional } from "./types";

/**
 * Takes in an ISO Date and creates a JS date object matching the date (ignoring timezone)
 *
 * This is needed since using Date.parse() in the browser will treat the ISO date as UTC
 * and then convert it to the users' timezone, which could result in an incorrect date.
 * See: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/parse#using_date.parse
 * */
export const fromISODate = (val: string) => {
  const [year, month, day] = val.split("-");
  if (!year || !month || !day) {
    console.warn(`Invalid date supplied to fromISODate: ${val}`);
    return new Date();
  }

  const yearNum = Number.parseInt(year, 10);
  const monthNum = Number.parseInt(month, 10) - 1;
  const dayNum = Number.parseInt(day, 10);

  return new Date(yearNum, monthNum, dayNum);
};

/**
 * Parses an ISO date time string into a JS date object
 *
 * Thin wrapper around new Date just to make it clear that we're expecting an ISO date time string
 * and allow for modifying the implementation in the future if needed
 */
export const fromISODateTime = (val: string) => {
  return new Date(val);
};

/** TODO: Remove this once we take out CLINIC_TIMEZONE feature flag */
export const fromISODateTimeInTimezone = (val: string, timezone: string) => {
  const dt = fromISODateTime(val);
  return new Date(dt.toLocaleString("en-US", { timeZone: timezone }));
};

export type DateOrString = Date | string;

/** Util generally used to map external date representations
 * (eg. either a date or a string) to a js Date used by most other utils
 * in this file. This is useful for supporting the the same set of values as
 * input to these utils
 * @argument val either a `Date` or a ISO date string without time part, or
 * any valid `dateString` that `new Date` accepts */
export const normalizeToDate = (val: DateOrString) => {
  if (typeof val !== "string") {
    // Copy any dates before performing any operations on them to avoid subtly mutating dates
    return new Date(val.getTime());
  }
  const [date, time = "", ...rest] = val.split(/T|\s/);
  if (date.length && !time.length && !rest.length) {
    return fromISODate(val);
  }
  return new Date(val);
};

/**
 * Helper to get locale date strings in the clinic timezone
 */
export const toLocaleDate = (
  dateOrString: DateOrString,
  options: Intl.DateTimeFormatOptions = {
    month: "short",
    day: "numeric",
    weekday: "short",
    year: "numeric",
  },
) => {
  const date = normalizeToDate(dateOrString);
  const timezone =
    typeof window === "undefined" || !window.CLINIC_TIMEZONE
      ? {}
      : { timeZone: window.CLINIC_TIMEZONE };
  return date.toLocaleString("en-US", { ...timezone, ...options });
};

/** Helper to get local Date in clinic timezone */
export const toClinicDate = (dateOrString: DateOrString) => {
  return new Date(toLocaleDate(dateOrString, {}));
};

/**
 * Returns timezone abbreviation (e.g. PST, EST) for a given date and timezone
 */
export const getTimezoneAbbreviation = () => {
  const timezone =
    typeof window === "undefined" || !window.CLINIC_TIMEZONE
      ? {}
      : { timeZone: window.CLINIC_TIMEZONE };
  return (
    new Intl.DateTimeFormat("en-US", {
      ...timezone,
      timeZoneName: "short",
    })
      .formatToParts(new Date())
      .find((part) => part.type === "timeZoneName")?.value || ""
  );
};

/**
 * Returns the difference in hours between the clinic timezone and local time
 * Positive values indicate the clinic timezone is ahead of local time
 * Negative values indicate the clinic timezone is behind local time
 */
export const getNumHoursClinicAhead = () => {
  const date = new Date();

  // Get the target timezone time
  const clinicTime = toClinicDate(date);

  // Get local time
  const localTime = new Date(date.toLocaleString("en-US"));

  // Calculate difference in hours
  return (clinicTime.getTime() - localTime.getTime()) / (1000 * 60 * 60);
};

/**
 * Take in a ISO date and return an object with the day, month, and year
 */
export const dateParts = (val: DateOrString) => {
  const date = normalizeToDate(val);
  return {
    day: date.getUTCDate(),
    month: date.getUTCMonth() + 1,
    year: date.getFullYear(),
  };
};

/**
 * Take in a day, month, and year and return a string of the date if all values are present
 */
export const toDateFromParts = ({
  day,
  month,
  year,
}: {
  day: string | undefined;
  month: string | undefined;
  year: string | undefined;
}) => {
  return day && month && year ? `${year}-${month}-${day}` : undefined;
};

/**
 * Useful for any components where we are only managing the date parts and
 * don't want time to add noise for comparisons, etc.
 */
export const atMidnight = (dateOrString: DateOrString) => {
  // Get the date in the clinic timezone
  const clinicDate = toClinicDate(dateOrString);
  // Set to midnight in clinic timezone
  clinicDate.setHours(-getNumHoursClinicAhead(), 0, 0, 0);

  return clinicDate;
};

/** Takes in a date and converts to an ISO string like '2020-02-23' */
export const toISODate = (value: Date | string) => {
  const date = normalizeToDate(value);
  return date.toISOString().substring(0, 10);
};

/** Takes in a date and converts to an ISO string like '2020-02-23T12:00:00 */
export const toISODateTime = (value: Date | string) => {
  const date = normalizeToDate(value);
  return date.toISOString();
};

export const toLocalISODate = (date: Date) => {
  return [
    date.getFullYear(),
    `0${date.getMonth() + 1}`.slice(-2),
    `0${date.getDate()}`.slice(-2),
  ].join("-");
};

/** Returns whether the provided date is valid */
export const isValidDate = (dt: DateOrString) => {
  // don't use normalizeToDate here since fromISODate will "fix" invalid dates
  const d = new Date(dt);
  return !Number.isNaN(d.getTime());
};

/** Returns whether the provided dates are in the same year */
export const isSameYear = (a: DateOrString, b: DateOrString) => {
  const aDate = normalizeToDate(a);
  const bDate = normalizeToDate(b);

  return aDate.getFullYear() === bDate.getFullYear();
};

/** Returns whether the provided dates are the same day */
export const isSameDate = (a: Optional<DateOrString>, b: Optional<DateOrString>) => {
  if (!a && !b) {
    return true;
  }
  if (!a || !b) {
    return false;
  }

  const aDate = normalizeToDate(a);
  const bDate = normalizeToDate(b);

  return (
    aDate.getFullYear() === bDate.getFullYear() &&
    aDate.getDate() === bDate.getDate() &&
    aDate.getMonth() === bDate.getMonth()
  );
};

/** Returns whether the provided dates are the same day and time (down to the minute) */
export const isSameDateTime = (a: Optional<DateOrString>, b: Optional<DateOrString>) => {
  if (!a && !b) {
    return true;
  }
  if (!a || !b) {
    return false;
  }

  const sameDate = isSameDate(a, b);
  const aDate = normalizeToDate(a);
  const bDate = normalizeToDate(b);

  return (
    sameDate && aDate.getHours() === bDate.getHours() && aDate.getMinutes() === bDate.getMinutes()
  );
};

/** Returns the provided date with a given number of years added */
export const addYears = (dt: DateOrString, years: number) => {
  const date = normalizeToDate(dt);
  date.setFullYear(date.getFullYear() + years);
  return date;
};

/** Returns the provided date with a given number of months added */
export const addMonths = (dt: DateOrString, months: number) => {
  const date = normalizeToDate(dt);
  const nextMonth = normalizeToDate(dt);
  nextMonth.setMonth(date.getMonth() + months);
  if (nextMonth.getDate() < date.getDate()) {
    nextMonth.setDate(0);
  }
  return nextMonth;
};

/** Returns the provided date with a given number of days added */
export const addDays = (dt: DateOrString, days: number) => {
  const date = normalizeToDate(dt);
  date.setDate(date.getDate() + days);
  return date;
};

/** Returns the provided date with a given number of minutes added */
export const addMinutes = (dt: DateOrString, minutes: number) => {
  const date = normalizeToDate(dt);
  const currentMilliseconds = date.getTime();
  return new Date(currentMilliseconds + minutes * 60 * 1000);
};

/** Returns the provided date with a given number of hours added */
export const addHours = (dt: DateOrString, hours: number) => {
  return addMinutes(dt, hours * 60);
};

/** Returns the provided date set to the time based on the minutes provided */
export const setMinutes = (dt: DateOrString, minutes: number) => {
  const date = normalizeToDate(dt);
  // Set the hours from the start of the day in the clinic timezone
  date.setHours(-getNumHoursClinicAhead());
  date.setMinutes(minutes);
  return date;
};

/** Returns the provided date with a given number of weeks added */
export const addWeeks = (dt: DateOrString, days: number) => {
  return addDays(dt, days * 7);
};

export const minuteDifference = (a: DateOrString, b: DateOrString) => {
  const aDate = normalizeToDate(a);
  const bDate = normalizeToDate(b);

  return Math.round((aDate.getTime() - bDate.getTime()) / 1000 / 60);
};

/**
 * Helper to get locale time strings
 */
export const toLocaleTime = (
  dateOrString: DateOrString,
  options: Intl.DateTimeFormatOptions = {
    hour: "numeric",
    minute: "numeric",
  },
) => {
  const date = normalizeToDate(dateOrString);

  return date.toLocaleTimeString("en-US", options);
};

/**
 * Default date renderer in the app
 *
 * EX:
 * Jan 1, 2020
 * May 5, 2020
 * Dec 10, 2020
 */
export const toDate = (dt: DateOrString) =>
  toLocaleDate(dt, { month: "short", day: "numeric", year: "numeric" });

/**
 * Number date renderer
 *
 * EX:
 * 01/05/20
 * 05/05/20
 * 12/10/20
 */
export const toNumberDate = (dt: DateOrString) =>
  toLocaleDate(dt, {
    year: "2-digit",
    month: "2-digit",
    day: "2-digit",
  });

/**
 * Default time renderer in the app
 *
 * EX:
 * 4:10 PM
 * 10:04 AM
 * 12:12 PM
 */
export const toTime = (dt: DateOrString) => toLocaleDate(dt, { timeStyle: "short" });

/**
 * Condensed time renderer in the app
 *
 * EX:
 * 4 PM
 * 10:04 AM
 * 12:12 PM
 */
export const toCondensedTime = (dt: DateOrString) => {
  const time = toTime(dt);
  const [hoursMinutes, period] = time.split(" ");
  const [hours, minutes] = hoursMinutes.split(":");

  if (minutes === "00") {
    return `${hours} ${period}`;
  }

  return time;
};

/**
 * Condensed time range renderer in the app
 *
 * EX:
 * 4 - 5 PM
 * 11 AM - 1 PM
 * 12:12 - 1:02 PM
 * 11:12 AM - 1:02 PM
 */
export const toCondensedTimeRange = (start: DateOrString, end: DateOrString) => {
  const startTime = toCondensedTime(start);
  const [startMinutes, startPeriod] = startTime.split(" ");

  const endTime = toCondensedTime(end);
  const [endMinutes, endPeriod] = endTime.split(" ");

  return `${startMinutes}${
    startPeriod === endPeriod ? "" : ` ${startPeriod}`
  } - ${endMinutes} ${endPeriod}`;
};

/**
 * Default date time renderer in the app
 *
 * EX:
 * Jan 1, 2020 at 4:10 PM
 * May 5, 2020 at 10:04 AM
 * Dec 10, 2020 at 12:12 PM
 */
export const toDateTime = (dt: DateOrString) =>
  `${toLocaleDate(dt, { month: "short", day: "numeric", year: "numeric" })} at ${toTime(dt)}`;

/**
 * Default date range renderer in the app
 *
 * EX:
 * Jan 1 - May 5, 2020
 * May 5, 2020 - June 5, 2021
 * Dec 10 - Dec 12, 2020
 */
export const toDateRange = (
  start: DateOrString | null,
  end: DateOrString | null,
  sep: string = "-",
) => {
  if (start && end) {
    const startDate = normalizeToDate(start);
    const endDate = normalizeToDate(end);

    if (isSameDate(startDate, endDate)) {
      return toDate(startDate);
    }

    return `${
      isSameYear(startDate, endDate)
        ? toLocaleDate(startDate, { month: "short", day: "numeric" })
        : toDate(startDate)
    } ${sep} ${toDate(endDate)}`;
  }
  if (!start && end) {
    const endDate = normalizeToDate(end);
    return `None ${sep} ${toDate(endDate)}`;
  }
  if (!end && start) {
    const startDate = normalizeToDate(start);
    return `${toDate(startDate)} ${sep} None`;
  }
  return `None ${sep} None`;
};

type ToConversationDateTimeOpts = {
  now?: DateOrString;
  isCapitalized?: boolean;
};

export const toConversationDate = (dt: DateOrString, opts?: ToConversationDateTimeOpts) => {
  const { now: nowOpt, isCapitalized = true } = opts ?? {};

  const now = normalizeToDate(nowOpt ?? new Date());

  if (isSameDate(dt, now)) {
    return `${isCapitalized ? "Today" : "today"}`;
  }

  if (isSameDate(addDays(dt, 1), now)) {
    return `${isCapitalized ? "Yesterday" : "yesterday"}`;
  }

  return toDate(dt);
};

/**
 * Dates in a conversation
 *
 * EX:
 * Today at 10:00 AM
 * Yesterday at 2:00 PM
 * July 12 at 4:00 PM
 * August 12, 2022 at 4:00 PM
 */
export const toConversationDateTime = (dt: DateOrString, opts?: ToConversationDateTimeOpts) => {
  const date = toConversationDate(dt, opts);
  const time = toLocaleDate(dt, { timeStyle: "short" });

  return `${date} at ${time}`;
};

enum Unit {
  Second = "SECOND",
  Minute = "MINUTE",
  Hour = "HOUR",
  Day = "DAY",
  Week = "WEEK",
  Month = "MONTH",
  Year = "YEAR",
}

const UNIT_TO_DISPLAY: Record<Unit, string> = {
  [Unit.Second]: "second",
  [Unit.Minute]: "minute",
  [Unit.Hour]: "hour",
  [Unit.Day]: "day",
  [Unit.Week]: "week",
  [Unit.Month]: "month",
  [Unit.Year]: "year",
};

const UNIT_TO_CONDENSED_DISPLAY: Record<Unit, string> = {
  [Unit.Second]: "s",
  [Unit.Minute]: "m",
  [Unit.Hour]: "h",
  [Unit.Day]: "d",
  [Unit.Week]: "w",
  [Unit.Month]: "mo",
  [Unit.Year]: "y",
};

type ToRelativeDateOpts = {
  futureSuffix?: string;
  isCondensed?: boolean;
};

/**
 * Relative date formatter
 *
 * EX:
 * A few seconds ago
 * 2 days ago
 * 3 weeks ago
 * 2 months from now
 * 1 year from now
 */
export const toRelativeDate = (
  dt: DateOrString,
  opts?: ToRelativeDateOpts,
  now_?: DateOrString,
) => {
  const { futureSuffix = " from now" } = opts ?? {};

  const date = normalizeToDate(dt).getTime();
  const now = normalizeToDate(now_ ?? new Date()).getTime();

  const [start, end, suffix] = date > now ? [date, now, futureSuffix] : [now, date, " ago"];

  const seconds = Math.round((start - end) / 1000);
  const minutes = Math.round(seconds / 60);
  const hours = Math.round(seconds / 3600);
  const days = Math.round(seconds / 86400);
  const months = Math.round(seconds / 2592000);
  const years = Math.round(seconds / 31536000);

  let value: string | number | null = null;
  let unit: Unit | null = null;
  let internalValue: number | null = null;

  if (days > 548) {
    value = years;
    internalValue = years;
    unit = Unit.Year;
  } else if (days >= 320 && days <= 547) {
    value = "a";
    internalValue = 1;
    unit = Unit.Year;
  } else if (days >= 45 && days <= 319) {
    value = months;
    internalValue = months;
    unit = Unit.Month;
  } else if (days >= 26 && days <= 45) {
    value = "a";
    internalValue = 1;
    unit = Unit.Month;
  } else if (hours >= 36 && days <= 25) {
    value = days;
    internalValue = days;
    unit = Unit.Day;
  } else if (hours >= 22 && hours <= 35) {
    value = "a";
    internalValue = 1;
    unit = Unit.Day;
  } else if (minutes >= 90 && hours <= 21) {
    value = hours;
    internalValue = hours;
    unit = Unit.Hour;
  } else if (minutes >= 45 && minutes <= 89) {
    value = "an";
    internalValue = 1;
    unit = Unit.Hour;
  } else if (seconds >= 90 && minutes <= 44) {
    value = minutes;
    internalValue = minutes;
    unit = Unit.Minute;
  } else if (seconds >= 45 && seconds <= 89) {
    value = "a";
    internalValue = 1;
    unit = Unit.Minute;
  } else if (seconds >= 0 && seconds <= 45) {
    value = "a few";
    internalValue = 15;
    unit = Unit.Second;
  }

  if (!value || !unit || !internalValue) {
    return "Invalid Date";
  }

  if (opts?.isCondensed) {
    return `${internalValue}${UNIT_TO_CONDENSED_DISPLAY[unit]}`;
  }

  return `${value} ${UNIT_TO_DISPLAY[unit]}${internalValue > 1 ? "s" : ""}${suffix}`;
};

/**
 * Gets the ordinal for a date
 *
 * 1 -> st
 * 2 -> nd
 * 3 -> rd
 * 4 -> th
 * 11 -> th
 * 32 -> nd
 * 38 -> th
 */
export const toOrdinal = (dateOrString: DateOrString) => {
  const date = normalizeToDate(dateOrString);
  const number = date.getDate();
  return toNumberOrdinal(number);
};

export const today = () => {
  const date = new Date();
  date.setHours(0, 0, 0, 0);
  return date;
};

export const padTime = (time: number | string) => `${time}`.padStart(2, "0");

export const getTimeFromDate = (dateStr: string) => {
  const date = new Date(dateStr);
  return `${date.getHours()}:${padTime(date.getMinutes())}:${padTime(date.getSeconds())}`;
};

/**
 * Returns the number of days in a given month
 *
 * From https://stackoverflow.com/questions/1184334/get-number-days-in-a-specified-month-using-javascript
 */
export const daysInMonth = (month: number, year?: number) => {
  const y = year ?? new Date().getFullYear();
  // Month in JavaScript is 0-indexed (January is 0, February is 1, etc),
  // but by using 0 as the day it will give us the last day of the prior
  // month. So passing in 1 as the month number will return the last day
  // of January, not February
  return new Date(y, month, 0).getDate();
};

export const todayNormalizedForLeapYear = () => {
  const todayTime = today();
  const todayNormalizedForLeapYearTime =
    todayTime.getMonth() === 1 && todayTime.getDate() === 29 ? addDays(todayTime, -1) : todayTime;

  return todayNormalizedForLeapYearTime;
};

export const toAge = (date: DateOrString, now?: DateOrString) => {
  const dob = normalizeToDate(date);
  const normalizedNow = normalizeToDate(now ?? new Date());
  const age = normalizedNow.getFullYear() - dob.getFullYear();
  if (
    normalizedNow.getMonth() < dob.getMonth() ||
    (normalizedNow.getMonth() === dob.getMonth() && normalizedNow.getDate() < dob.getDate())
  ) {
    return age - 1;
  }
  return age;
};

export const getTimezone = () => {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
};
