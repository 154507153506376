import { QueryClient, useIsFetching, useIsMutating, useQueryClient } from "@tanstack/react-query";

import { SubscriptionFrequency } from "common/types";
import {
  ICart,
  CartApplyPromoCodesMutation,
  CartApplyPromoCodeResponse,
  ICartItem,
  ICartTax,
  ApplyAllPromoCodesMutation,
  ApplyAllPromoCodesResponse,
  ICartItemSubscriptionUpdate,
  CartApplyPromoCodeErrorData,
  CartDeliveryUpdate,
  CrossSellItem,
} from "common/types/carts";
import { Optional } from "common/utils/types";
import { useFetchMutation, useFetchQuery, useOptimisticFetchMutation } from "common/queries/hooks";
import { FetchError } from "common/fetch/errors";

import { sendAddToCart, sendRemoveFromCart } from "client/analytics/utils";

import {
  CartCode,
  CartRefillTooSoonSummary,
  ICartItemAssignment,
  ICartItemCreate,
  ICartItemDetail,
  ICartItemMutateV2,
  CartItemUpdateV2,
  ICartUpdate,
  CartDeliveryMutate,
  ICartItemUpdate,
  ControlledSubstancesSummary,
  ControlledSubstancesSummaryMutation,
} from "../types";
import { keys, urls } from "./constants";
import { useCartDrawer } from "../stackables";

const noop = () => undefined;
const getCartData = (queryClient: QueryClient, id: number): ICart | undefined => {
  return queryClient.getQueryData<ICart>(keys.cartDetail(id));
};

export const setCartData = (
  queryClient: QueryClient,
  id: number,
  newCart: ICart,
  { replaceExisting = true }: { replaceExisting?: boolean } = {},
) => {
  if (replaceExisting || !getCartData(queryClient, id)) {
    queryClient.setQueryData<ICart>(keys.cartDetail(id), newCart);
  }
  // cartId might point to the cart the FE currently has as the "active" cart
  // We should also reassign to that key so that changes are immediately shown
  // on pages that display the active cart
  const activeCartKey = keys.activeCartDetail();
  const activeCart = queryClient.getQueryData<ICart>(activeCartKey);
  if (replaceExisting && activeCart?.id === id) {
    // we need to invalidate all variations of query params for an active cart
    queryClient.setQueriesData(activeCartKey, newCart);
  }
};

const updateCartData = (queryClient: QueryClient, cartId: number, updates: Partial<ICart>) => {
  const currentCart = getCartData(queryClient, cartId);
  if (!currentCart) {
    return [undefined, undefined];
  }
  const newCart = { ...currentCart, ...updates };
  setCartData(queryClient, cartId, newCart, { replaceExisting: true });
  return [currentCart, newCart];
};

const updateCartItemInCart = (
  queryClient: QueryClient,
  cartId: number,
  cartItemId: number,
  data: Partial<ICartItem>,
) => {
  const cart = getCartData(queryClient, cartId);
  if (!cart) {
    return undefined;
  }
  const newItems = cart.items.map((cartItem) =>
    cartItem.id === cartItemId ? { ...cartItem, ...data } : cartItem,
  );
  setCartData(queryClient, cartId, { ...cart, items: newItems });
  return cart;
};

const getCartItemFromCart = (queryClient: QueryClient, cartId: number, cartItemId: number) => {
  const cart = getCartData(queryClient, cartId);
  return cart?.items.find((ci) => ci.id === cartItemId);
};

export const useIsCartItemUpdating = () => {
  return !!useIsMutating(keys.mutateCartItems());
};

export const useIsCartUpdating = () => {
  const isFetching = useIsFetching(keys.activeCartDetail());
  const isMutating = useIsMutating(keys.mutations());

  return !!isFetching || !!isMutating;
};

export const useCartQuery = (id: Optional<number>) => {
  const queryId = id ?? 0;
  return useFetchQuery<ICart>(keys.cartDetail(queryId), {
    fetch: { url: urls.cart(queryId) },
    config: {
      enabled: !!id,
    },
  });
};

export const useActiveCartQuery = ({ enabled = true }: { enabled?: boolean } = {}) => {
  const queryClient = useQueryClient();
  return useFetchQuery<ICart>(keys.activeCartDetail(), {
    fetch: { url: urls.cart(CartCode.ACTIVE) },
    config: {
      onSuccess: (cart) => {
        // Keep 'active' cart in sync with it's id-keyed counterpart
        queryClient.setQueryData(keys.cartDetail(cart.id), cart);
      },
      enabled,
    },
  });
};

export const useCartTaxQuery = (id: number, { enabled = true }: { enabled?: boolean } = {}) => {
  return useFetchQuery<ICartTax>(keys.cartTax(id), {
    fetch: { url: urls.cartTax(id) },
    config: {
      enabled,
    },
  });
};

type CartUpdateMutation = {
  body: Partial<ICartUpdate>;
  itemUpdates?: (Pick<ICartItem, "id"> & Partial<ICartItem>)[];
  cartUpdate?: Partial<Omit<ICart, "items">>;
  invalidateTax?: boolean;
};

export const useUpdateCartDeliveryMutation = (
  id: number,
  {
    onError = noop,
    onSuccess = noop,
  }: { onError?: () => void; onSuccess?: (response: void) => void } = {},
) => {
  return useOptimisticFetchMutation<CartDeliveryMutate, void, ICart, CartDeliveryUpdate[]>({
    fetch: ({ body }) => ({ method: "PATCH", url: urls.cartDelivery(id), body }),
    config: {
      mutationKey: keys.mutateCarts(),
      onError,
      onSuccess,
    },
    optimistic: {
      dataKeys: [keys.cartDetails()],
      invalidateKeys: [keys.cartTax(id), keys.carts()],
      update: (cart, { optimisticData }) => {
        if (cart.id !== id) {
          // sanity check
          return cart;
        }
        return {
          ...cart,
          delivery_groups:
            optimisticData?.newDeliveryGroups !== undefined
              ? optimisticData.newDeliveryGroups
              : cart.delivery_groups,
          items:
            optimisticData?.newCartItems !== undefined ? optimisticData.newCartItems : cart.items,
          is_all_pickup:
            optimisticData?.isAllPickup !== undefined
              ? optimisticData.isAllPickup
              : cart.is_all_pickup,
          is_delivery_info_complete:
            optimisticData?.isDeliveryInfoComplete !== undefined
              ? optimisticData.isDeliveryInfoComplete
              : cart.is_delivery_info_complete,
        };
      },
    },
  });
};

export const useCartUpdateMutation = (
  id: number,
  { onSuccess = noop }: { onSuccess?: (cart: ICart) => void } = {},
) => {
  const queryClient = useQueryClient();
  return useFetchMutation<CartUpdateMutation, ICart, ICart, Partial<ICartUpdate>>({
    fetch: ({ body }) => ({
      method: "PATCH",
      url: urls.cart(id),
      body,
    }),
    config: {
      mutationKey: keys.mutateCarts(),
      onMutate: ({ itemUpdates, cartUpdate, invalidateTax = true }) => {
        queryClient.cancelQueries(keys.cartDetails());
        if (invalidateTax) {
          queryClient.invalidateQueries(keys.cartTax(id));
        }
        queryClient.invalidateQueries(keys.cartRefillTooSoonSummary(id));
        const [previousCart] = updateCartData(queryClient, id, {
          ...cartUpdate,
        });
        itemUpdates?.forEach((itemUpdate) => {
          updateCartItemInCart(queryClient, id, itemUpdate.id, itemUpdate);
        });

        return previousCart;
      },
      onSettled: () => queryClient.invalidateQueries(keys.carts()),
      onSuccess,
      onError: (_error, _variables, previousCart) =>
        previousCart && setCartData(queryClient, id, previousCart),
    },
  });
};

/**
 * Reconciles the cart item with the updates from the cart item mutation and the optimistic data
 */
const reconcileCartItemWithOptimisticData = (
  cartItem: ICartItem,
  updateBody: CartItemUpdateV2,
  optimisticData: ICartItemMutateV2["optimisticData"] = {},
): ICartItem => {
  // get all values in the body which *don't* correspond to optimistic data
  const fromBody = Object.fromEntries(
    Object.entries(updateBody).filter(([k]) => !(k in optimisticData)),
  );
  // get all values in the optimistic data that have changed
  const fromOptimistic = Object.fromEntries(
    Object.entries(optimisticData).filter(([, v]) => v !== undefined),
  );
  return {
    ...cartItem,
    ...fromBody,
    ...fromOptimistic,
  };
};

export const useCartItemUpdateMutationV2 = (
  cartItemId: number,
  cartId: number,
  {
    onError = noop,
    onSuccess = noop,
  }: { onError?: () => void; onSuccess?: (response: null) => void } = {},
) => {
  return useOptimisticFetchMutation<ICartItemMutateV2, null, ICart, CartItemUpdateV2>({
    fetch: (mutateData) => ({
      method: "PATCH",
      url: urls.cartItem(cartItemId, "v2"),
      body: mutateData.body,
    }),
    config: {
      mutationKey: keys.mutateCartItems(),
      onError,
      onSuccess,
    },
    optimistic: {
      dataKeys: [keys.cartDetails()],
      invalidateKeys: [keys.carts(), keys.cartTax(cartId), keys.cartRefillTooSoonSummary(cartId)],
      update: (oldCart, update) => {
        if (oldCart.id !== cartId) {
          // sanity check
          return oldCart;
        }
        const newItems = oldCart.items.map((cartItem) =>
          cartItem.id === cartItemId
            ? reconcileCartItemWithOptimisticData(cartItem, update.body, update.optimisticData)
            : cartItem,
        );
        return { ...oldCart, items: newItems };
      },
    },
  });
};

/** @deprecated you can consume it if needed, don't extend it.
 * Add new functionaility to useCartItemUpdateMutationv2 * */
export const useCartItemUpdateMutation = (
  cartId: number,
  cartItemId: number,
  { onSuccess = noop }: { onSuccess?: (cartItem: ICartItemDetail) => void } = {},
) => {
  const queryClient = useQueryClient();
  return useFetchMutation<ICartItemUpdate, ICartItemDetail, ICart>({
    fetch: (body) => ({
      method: "PATCH",
      url: urls.cartItem(cartItemId),
      body,
    }),
    config: {
      mutationKey: keys.mutateCartItems(),
      // Optimistically update the cart so UI update is instant
      onMutate: (body) => {
        queryClient.cancelQueries(keys.cartDetails());
        // need to clear `item` from updates so it doesn't replace structured item with an id
        const updates = {
          ...body,
        };
        delete updates.item;
        return updateCartItemInCart(
          queryClient,
          cartId,
          cartItemId,
          updates as Omit<typeof updates, "item">,
        );
      },
      onSettled: () => queryClient.invalidateQueries(keys.carts()),
      onError: (_error, _variables, prevCart) =>
        prevCart && setCartData(queryClient, cartId, prevCart),
      onSuccess,
    },
  });
};

export const useCartItemAssignmentMutation = (
  cartId: number,
  { onSuccess = noop }: { onSuccess?: (cart: ICart) => void } = {},
) => {
  const queryClient = useQueryClient();
  return useFetchMutation<ICartItemAssignment, ICart>({
    fetch: (body) => ({
      method: "PATCH",
      url: urls.cartItemAssignment(cartId),
      body,
    }),
    config: {
      mutationKey: keys.mutateCarts(),
      onSuccess: (cart) => {
        setCartData(queryClient, cartId, cart);
        onSuccess(cart);
      },
    },
  });
};

export const useCartItemSubscriptionCreateUpdateMutation = (
  cartId: number,
  cartItemId: number,
  { onSuccess = noop }: { onSuccess?: () => void } = {},
) => {
  const queryClient = useQueryClient();
  return useFetchMutation<SubscriptionFrequency, unknown, ICart, ICartItemSubscriptionUpdate>({
    fetch: (body) => ({
      method: "PUT",
      url: urls.cartItemSubscription(cartItemId),
      body,
    }),
    config: {
      mutationKey: keys.mutateCartItems(),
      // Optimistically update the cart so UI update is instant
      onMutate: (body) => {
        queryClient.cancelQueries(keys.cartDetails());
        const cartItem = getCartItemFromCart(queryClient, cartId, cartItemId);
        if (cartItem) {
          return updateCartItemInCart(queryClient, cartId, cartItemId, {
            subscription: {
              ...body,
              frequency_display: body.display,
            },
            existing_subscription_modifications: null,
          });
        }
        return undefined;
      },
      onSettled: () => queryClient.invalidateQueries(keys.carts()),
      onError: (_error, _variables, prevCart) =>
        prevCart && setCartData(queryClient, cartId, prevCart),
      onSuccess,
    },
  });
};

export const useCartItemSubscriptionDeleteMutation = (
  cartId: number,
  cartItemId: number,
  { onSuccess = noop }: { onSuccess?: () => void } = {},
) => {
  const queryClient = useQueryClient();
  return useFetchMutation<void, unknown, ICart>({
    fetch: () => ({
      method: "DELETE",
      url: urls.cartItemSubscription(cartItemId),
    }),
    config: {
      mutationKey: keys.mutateCartItems(),
      // Optimistically update the cart so UI update is instant
      onMutate: () => {
        queryClient.cancelQueries(keys.cartDetails());
        return updateCartItemInCart(queryClient, cartId, cartItemId, { subscription: undefined });
      },
      onSettled: () => queryClient.invalidateQueries(keys.carts()),
      onError: (_error, _variables, prevCart) =>
        prevCart && setCartData(queryClient, cartId, prevCart),
      onSuccess,
    },
  });
};

export const useCartItemDeleteMutation = (
  cartId: number,
  { onSuccess = noop }: { onSuccess?: (id: number) => void } = {},
) => {
  const queryClient = useQueryClient();
  // explicit FetchError type needed to suppress typescript errors when a developer changes branches
  return useFetchMutation<number, unknown, ICart, undefined, FetchError>({
    fetch: (id) => ({ method: "DELETE", url: urls.cartItem(id) }),
    config: {
      mutationKey: keys.mutateCartItems(),
      onMutate: (id) => {
        queryClient.cancelQueries(keys.cartDetails());
        // Get the current (stale) cart
        // Try the cart by id first, if can't find, then try by the ActiveCartKey
        const cart: ICart | undefined =
          queryClient.getQueryData(keys.cartDetail(cartId)) ??
          queryClient.getQueryData<ICart | undefined>(keys.activeCartDetail());
        if (!cart) {
          return undefined;
        }
        // Delete the item from the FE cart so the UI updates right away
        const newCart = {
          ...cart,
          items: cart.items.filter((cartItem) => cartItem.id !== id),
        };
        setCartData(queryClient, cartId, newCart);
        return cart;
      },
      onSettled: () => queryClient.invalidateQueries(keys.carts()),
      onError: (_error, _variables, prevCart) =>
        prevCart && setCartData(queryClient, cartId, prevCart),
      onSuccess: (_data, id, oldCart) => {
        onSuccess(id);
        const deleted = oldCart?.items.find((ci) => ci.id === id);
        if (deleted) {
          sendRemoveFromCart(deleted, {
            quantity: deleted.quantity,
          });
        }
      },
    },
  });
};

export const useCartItemAddMutation = ({
  onSuccess = noop,
  shouldOpenCart = false,
}: {
  onSuccess?: (cartItem: ICartItemDetail) => void;
  shouldOpenCart?: boolean;
} = {}) => {
  const queryClient = useQueryClient();
  const { open: openCartDrawer } = useCartDrawer();

  return useFetchMutation<ICartItemCreate, ICartItemDetail>({
    fetch: (body) => ({ url: urls.cartItems(), body, method: "POST" }),
    config: {
      // don't use mutateCartItem key here (this was leading to bugs when also open the cart drawer after starting this mutation)
      // we should reassess after we upgrade our react-query version (currently on ^3.34.0)
      mutationKey: keys.mutateCarts(),
      onSuccess: (cartItem, body) => {
        if (shouldOpenCart) {
          // Remove the query to show a hard loading state in the cart as to not confuse the user
          // if the modal opens and their item isn't there for a second while it loads
          queryClient.removeQueries(keys.carts());
          openCartDrawer();
        } else {
          queryClient.invalidateQueries(keys.carts());
        }

        onSuccess(cartItem);
        const quantity = body.quantity ?? cartItem.quantity;
        sendAddToCart(cartItem, { quantity });
      },
    },
  });
};

export const useCartApplyPromoCodeMutation = ({
  cartId,
  onSuccess = noop,
}: {
  cartId: number;
  onSuccess?: () => void;
}) => {
  const queryClient = useQueryClient();
  return useFetchMutation<
    CartApplyPromoCodesMutation,
    CartApplyPromoCodeResponse,
    void,
    CartApplyPromoCodesMutation,
    FetchError<void, CartApplyPromoCodeErrorData>
  >({
    fetch: (body) => ({ method: "POST", url: urls.cartPromoCodes(cartId), body }),
    config: {
      onSuccess: () => {
        queryClient.invalidateQueries(keys.carts());
        onSuccess();
      },
    },
  });
};

export const useCartUnapplyPromoCodeMutation = ({
  cartId,
  promoId,
  onSuccess = noop,
}: {
  cartId: number;
  promoId: number;
  onSuccess?: () => void;
}) => {
  const queryClient = useQueryClient();
  return useFetchMutation<void, unknown, ICart>({
    fetch: () => ({ method: "DELETE", url: urls.cartPromoCode(cartId, promoId) }),
    config: {
      onMutate: () => {
        queryClient.cancelQueries(keys.carts());
        // Remove the code from the FE cart so the UI updates right away
        const cart: ICart | undefined =
          queryClient.getQueryData([keys.cartDetail(cartId)]) ??
          queryClient.getQueryData<ICart | undefined>(keys.activeCartDetail());
        if (cart) {
          // Remove the code from the FE cart so the UI updates right away
          const newCart = {
            ...cart,
            promo_codes: cart.promo_codes.filter((code) => code.id !== promoId),
          };
          setCartData(queryClient, cartId, newCart);
        }
        return cart;
      },
      onSettled: () => queryClient.invalidateQueries(keys.carts()),
      onError: (_err, _data, prevCart) => prevCart && setCartData(queryClient, cartId, prevCart),
      onSuccess,
    },
  });
};

export const useAddCartPromoCodesMutation = () => {
  return useFetchMutation<ApplyAllPromoCodesMutation, ApplyAllPromoCodesResponse>({
    fetch: (body) => ({ method: "POST", url: urls.promoCodesApplyAll(), body }),
  });
};

export const useCartRefillTooSoonSummaryQuery = (cartId: number, { enabled = true } = {}) => {
  return useFetchQuery<CartRefillTooSoonSummary>(keys.cartRefillTooSoonSummary(cartId), {
    fetch: { url: urls.cartRefillTooSoonSummary(cartId) },
    config: { enabled },
  });
};
export const useControlledSubstancesSummaryMutation = ({
  onSuccess = noop,
}: {
  onSuccess?: (summary: ControlledSubstancesSummary) => void;
} = {}) => {
  return useFetchMutation<ControlledSubstancesSummaryMutation, ControlledSubstancesSummary>({
    fetch: (body) => ({ method: "POST", url: urls.controlledSubstancesSummary(), body }),
    config: { onSuccess },
  });
};

export const useCrossSellsQuery = (cartId: number, { enabled = true } = {}) => {
  return useFetchQuery<CrossSellItem[]>(keys.crossSells(cartId), {
    fetch: { url: urls.crossSells(cartId) },
    config: { enabled },
  });
};
