import { faComments, faStars } from "@fortawesome/pro-regular-svg-icons";
import cx from "classnames";

import { toDollars } from "common/utils/formatting";
import Icon from "common/components/Icon";
import { BundleRenderType } from "common/types";

import { useClinic } from "client/clinics/hooks";
import { genSubscriptionDiscountContent } from "client/subscriptions/utils";
import { urls } from "client/routes";
import { useSubscriptionInfoDrawer } from "client/subscriptions/stackables";
import { useShippingInfoDrawer } from "client/common/partners/stackables";
import { useSupportChat } from "client/support/hooks";

import TextChip, { TextChipProps } from "../content/TextChip";
import Container from "../layouts/Container";
import TextButton from "../buttons/TextButton";

import styles from "client/common/styles/HeaderBanner.module.less";

export type HeaderBannerVariant = "default" | "semi-transparent" | "dark";

const variantToChipVariant: Record<HeaderBannerVariant, TextChipProps["variant"]> = {
  default: "promotion-fill",
  "semi-transparent": "transparent",
  dark: "transparent",
};

type HeaderBannerProps = {
  className?: string;
  variant?: HeaderBannerVariant;
};

const HeaderBanner = ({ className, variant = "default" }: HeaderBannerProps) => {
  const clinic = useClinic();
  const chat = useSupportChat();

  const { open: openSubscriptionInfo } = useSubscriptionInfoDrawer();
  const { open: openShippingInfoDrawer } = useShippingInfoDrawer();

  const renderChip = (text: string) => (
    <TextChip isInline={true} variant={variantToChipVariant[variant]}>
      {text}
    </TextChip>
  );

  const subscription = genSubscriptionDiscountContent(
    clinic.first_subscription_discount,
    clinic.recurring_subscription_discount,
    {
      none: () => null,
      onlyFirst: (first) => (
        <>
          {renderChip(`Save ${first}`)} <span className={styles.text}>on your first autoship*</span>
        </>
      ),
      onlyRecurring: (recurring) => (
        <>
          {renderChip(`Save ${recurring}`)} <span className={styles.text}>on all autoships*</span>
        </>
      ),
      both: (first, recurring) => (
        <>
          {renderChip(`Save ${first}`)}{" "}
          <span className={styles.text}>
            on your first autoship
            <span className={styles.long}> and {recurring} on future shipments*</span>
          </span>
        </>
      ),
    },
  );

  return (
    <div>
      <div className={cx(styles.banner, className, styles[`variant-${variant}`])}>
        <Container>
          <div className={styles.content}>
            {window.RENDER_TYPE !== BundleRenderType.EMBED ? (
              <TextButton
                icon="left"
                className={styles.home}
                variant={variant}
                {...(clinic.website_url
                  ? { href: clinic.website_url, newTab: false }
                  : { dest: urls.home.root() })}
              >
                Return to homepage
              </TextButton>
            ) : (
              <TextButton onClick={() => {}} variant={variant} />
            )}
            <div className={styles.message}>
              {subscription && (
                <TextButton type="button" onClick={openSubscriptionInfo} variant={variant}>
                  {subscription}
                </TextButton>
              )}

              {clinic.free_shipping_over && (
                <TextButton
                  onClick={openShippingInfoDrawer}
                  className={styles.shipping}
                  variant={variant}
                >
                  {subscription && clinic.free_shipping_over && (
                    <Icon className={styles.stars} icon={faStars} />
                  )}
                  Free shipping over {toDollars(clinic.free_shipping_over, { roundCents: true })}
                </TextButton>
              )}
            </div>
            <div className={styles.links}>
              <TextButton
                className={styles.help}
                dest={urls.support.helpCenter()}
                variant={variant}
              >
                FAQ
              </TextButton>
              <span>·</span>
              <TextButton className={styles.help} onClick={() => chat.show()} variant={variant}>
                <Icon className={styles.icon} icon={faComments} />
                Chat with us
              </TextButton>
            </div>
          </div>
        </Container>
      </div>
    </div>
  );
};

export default HeaderBanner;
