import cx from "classnames";
import { faTimes } from "@fortawesome/pro-regular-svg-icons";

import LinkButton from "common/components/LinkButton";
import Icon from "common/components/Icon";
import MarkdownParser from "common/components/MarkdownParser";

import { useAlertDeleteMutation, useAlertsQuery } from "client/alerts/hooks";
import { AppAlert } from "client/alerts/types";

import Container from "../layouts/Container";

import styles from "../../styles/HeaderAlert.module.less";

type AlertContentProps = {
  alert: AppAlert;
};

const AlertContent = ({ alert }: AlertContentProps) => {
  return <MarkdownParser.Inline className={styles.content} markdown={alert.content} />;
};

type AlertDismissButtonProps = {
  alert: AppAlert;
};

const AlertDismissButton = ({ alert }: AlertDismissButtonProps) => {
  const { mutate: dismissAlert } = useAlertDeleteMutation(alert.id);
  return (
    <LinkButton className={styles.dismissButton} onClick={() => dismissAlert()}>
      <Icon icon={faTimes} />
    </LinkButton>
  );
};

const HeaderAlerts = () => {
  const { data: alerts } = useAlertsQuery();

  if (!alerts) {
    return null;
  }

  return (
    <div className={styles.container}>
      {alerts.map((alert) => (
        <div key={alert.id}>
          <Container>
            <div className={cx(styles.alert, !alert.is_dismissible && styles.static)}>
              {/* Placeholder div to take up the same width as dismiss button to keep content centered */}
              <div />
              {alert.action_url ? (
                <LinkButton href={alert.action_url} className={styles.link}>
                  <AlertContent alert={alert} />
                </LinkButton>
              ) : (
                <AlertContent alert={alert} />
              )}
              <div className={styles.dismissButtonArea}>
                {alert.is_dismissible && <AlertDismissButton alert={alert} />}
              </div>
            </div>
          </Container>
        </div>
      ))}
    </div>
  );
};

export default HeaderAlerts;
