import { useMemo } from "react";
import { Remarkable } from "remarkable";

import DangerousInnerHTML from "./DangerousInnerHTML";

type MarkdownParserProps = {
  markdown: string;
  className?: string;
  hasHtml?: boolean;
};

const remarkable = new Remarkable({ html: false });
const remarkableWithHtml = new Remarkable({ html: true });

/**
 * Markdown Parser component - leveraging external libraries so make sure to lazy load this or just
 * use the Markdown component which handles this automatically with a fallback
 */
const MarkdownParser = ({ markdown, className = "", hasHtml = false }: MarkdownParserProps) => {
  const html = useMemo(
    () => (hasHtml ? remarkableWithHtml.render(markdown) : remarkable.render(markdown)),
    [markdown, hasHtml],
  );

  return <DangerousInnerHTML className={className} html={html} />;
};

type MarkdownInlineParserProps = Omit<MarkdownParserProps, "hasHtml">;

const inlineParser = new Remarkable({ html: false });
const isStrict = true; // Only allow what's enabled below, and no defaults
inlineParser.block.ruler.enable([], isStrict);
inlineParser.inline.ruler.enable(["text", "links", "emphasis", "mark"], isStrict);

const MarkdownInlineParser = ({ markdown, className = "" }: MarkdownInlineParserProps) => {
  const html = useMemo(() => inlineParser.renderInline(markdown), [markdown]);

  return <DangerousInnerHTML className={className} Node="span" html={html} />;
};

export default Object.assign(MarkdownParser, { Inline: MarkdownInlineParser });
