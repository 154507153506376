import { createContext } from "react";

import { FetchError } from "./errors";
import { FakeFetchOpts, FetchRequestOpts, RequestBody } from "./types";

export type FetchContextRequestOpts<T extends RequestBody> = FetchRequestOpts<T> & {
  onUnauthorized: (err: FetchError) => void;
  onNeedsRefresh: (err: FetchError) => void;
};

export type FetchContextValue<TResponseBody, TRequestBody extends RequestBody> = {
  fetch: (opts: FetchContextRequestOpts<TRequestBody>) => Promise<TResponseBody>;
  fakeFetch: (
    opts: FetchContextRequestOpts<TRequestBody>,
    genResponse: (body: TRequestBody | undefined) => TResponseBody,
    fakeFetchOpts?: FakeFetchOpts,
  ) => Promise<TResponseBody>;
};

/**
 * Context to define app specific fetch/fakeFetch methods. Generally a shallow
 * wrapper for `performFetch.ts` with some app specific config or error handling.
 *
 * @see {@link fetch/hooks!useFetch} for how to consume this state in general code
 */
const FetchContext = createContext<FetchContextValue<any, any>>({
  fetch: async () => console.warn("fetch is not bound"),
  fakeFetch: async () => console.warn("fakeFetch is not bound"),
});

export default FetchContext;
