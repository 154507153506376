/** Matches vendors constants VendorSlugs */
export enum VendorSlug {
  BlueBuffalo = "BLUE_BUFFALO",
  CentralGardenPet = "CGP",
  ExpressScripts = "EXPRESS_SCRIPTS",
  Hills = "HILLS",
  IVet = "IVET",
  Mixlab = "MIXLAB",
  Purina = "PURINA",
  RoyalCanin = "ROYAL_CANIN",
  Truepill = "TRUEPILL",
  Vetsource = "VETSOURCE",
  Wedgewood = "WEDGEWOOD",
}
