import errorImages from "common/constants/errorImages";
import Image from "common/components/Image";

import Button, { ButtonProps } from "../buttons/Button";

import styles from "../../styles/ErrorContainer.module.less";

export type ErrorContainerProps = {
  title?: React.ReactNode;
  description?: React.ReactNode;
  image?: string;
  imageAlt?: string;
  actions?: { label: string; onClick: () => void; variant?: ButtonProps["variant"] }[];
};

const ErrorContainer = ({
  image = errorImages.ErrorDefault,
  imageAlt = "Error",
  title = "Uh oh!",
  description = "Something went wrong.",
  actions = [],
}: ErrorContainerProps) => (
  <div className={styles.container}>
    <Image className={styles.image} src={image} alt={imageAlt} />
    <h1 className={styles.title}>{title}</h1>
    <p className={styles.description}>{description}</p>
    {!!actions.length && (
      <div className={styles.actions}>
        {actions.map(({ label, onClick, variant = "primary" }) => (
          <Button key={label} onClick={onClick} variant={variant}>
            {label}
          </Button>
        ))}
      </div>
    )}
  </div>
);

export default ErrorContainer;
