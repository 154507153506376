import Icon from "common/components/Icon";

import alertIcons from "client/common/constants/alertIcons";

import styles from "../../styles/ErrorMessage.module.less";

type ErrorMessageProps = {
  children: React.ReactNode;
};

const ErrorMessage = (props: ErrorMessageProps) => {
  const { children } = props;

  return (
    <div className={styles.container}>
      <Icon icon={alertIcons.danger} className={styles.icon} />
      {children}
    </div>
  );
};

export default ErrorMessage;
