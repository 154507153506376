import { useNoBodyScroll } from "common/hooks";

import Portal from "./Portal";

type ModalPortalProps = { children: React.ReactNode };

/**
 * @deprecated Replaced by Stackables
 * @see {@link stackables/hooks} for new implementation
 */
const ModalPortal = (props: ModalPortalProps) => {
  const { children } = props;

  // The body should never be scrollable when a Modal is open
  useNoBodyScroll();

  return <Portal>{children}</Portal>;
};

export default ModalPortal;
