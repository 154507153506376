import { ReactNode, useCallback, useMemo } from "react";
import * as Sentry from "@sentry/react";

import { FatalFetchError, FetchError } from "./errors";
import FetchContext, { FetchContextRequestOpts } from "./FetchContext";
import performFetch, { performFakeFetch } from "./performFetch";
import { FakeFetchOpts, FatalFetchErrorKind, FetchRequestOpts, RequestBody } from "./types";

export type FetchProviderProps = {
  /**
   * The token to be used for all requests. This is generally set on page load and
   * reset on login.
   */
  csrfToken: string;
  children: ReactNode;
};

const exhaustiveFatalFetchCheck = (kind: never) => {
  console.warn("unknown fatal fetch kind: ", kind);
};

/**
 * General provider to handle fetching for client requests.
 *
 * All apps should wrap their tree in this provider after they have access to authentication information.
 * The idea is that we can handle any unauthenticated requests that happen across the app by catching them here.
 *
 * @see {@link fetch/hooks!useFetch} for how to consume in general application code
 */
const FetchProvider = ({ csrfToken, children }: FetchProviderProps) => {
  const fetch = useCallback(
    ({ onUnauthorized, onNeedsRefresh: onNeedsReload, ...opts }: FetchContextRequestOpts<any>) => {
      const handleFatalFetchError = (error: FatalFetchError) => {
        switch (error.kind) {
          case FatalFetchErrorKind.Unauthorized:
            return onUnauthorized(error);
          case FatalFetchErrorKind.InvalidCSRFToken:
            return onNeedsReload(error);
          default:
            // if we can't do anything let's raise the same error so it isn't swallowed
            exhaustiveFatalFetchCheck(error.kind);
            throw error;
        }
      };

      return new Promise((resolve, reject) => {
        performFetch({ csrfToken, ...opts })
          .then((response) => resolve(response))
          .catch((error) => {
            if (error instanceof FatalFetchError) {
              try {
                handleFatalFetchError(error);
              } catch (callbackError) {
                console.error("Error in handing fatal fetch error");
                Sentry.captureException(callbackError);
                // let's reject the error still if we ran into trouble handling it
                // to not block the UI, and provide the user feedback on what may have gone wrong.
                reject(error);
              }
              // don't resolve fatal fetch errors. These should be handled explicitly
              // by the application, and should generally result in the application reloading
              return;
            }
            if (error instanceof FetchError) {
              reject(error);
              return;
            }
            // at this point let's not directly expose the error to the user because it
            // does not follow our standard error format and is something truly exceptional
            // let's log it for integrity and then throw a generic error
            // CONSIDER: downgrading to a warning depending on what comes through here
            Sentry.captureException(error);
            reject(new FetchError());
          });
      });
    },
    // we don't want to redefine fetch if the csrfToken changes, because
    // theoretically this could result in duplicate requests
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const fakeFetch = useCallback(
    async (
      fetchOpts: FetchRequestOpts<RequestBody>,
      genResponse: (body: RequestBody) => any,
      opts?: FakeFetchOpts,
    ) => {
      return performFakeFetch({ csrfToken, ...fetchOpts }, genResponse, opts);
    },
    // same as above
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const value = useMemo(
    () => ({
      fetch,
      fakeFetch,
    }),
    [fetch, fakeFetch],
  );

  return <FetchContext.Provider value={value}>{children}</FetchContext.Provider>;
};

export default FetchProvider;
