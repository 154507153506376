import { ComponentType } from "react";

import { eagerRoute, makeRoutes, MakeRoutesOpts } from "common/router/utils/routes";
import { makeBasePath, makeParentUrls } from "common/router/utils/urls";

import { AccountPaths, useAccountRoutes, makeAccountUrls } from "client/accounts/routes";
import { CheckoutPaths, useCheckoutRoutes, makeCheckoutUrls } from "client/checkout/routes";
import { LinkPaths, useLinkRoutes, makeLinkUrls } from "client/links/routes";
import { useOnboardingRoutes, makeOnboardingUrls, OnboardingPaths } from "client/onboarding/routes";
import { useOrderRoutes, makeOrderUrls, OrderPaths } from "client/orders/routes";
import {
  usePrescriptionRoutes,
  makePrescriptionUrls,
  PrescriptionPaths,
} from "client/prescriptions/routes";
import { useProductRoutes, makeProductUrls, ProductPaths } from "client/products/routes";
import { useSchedulerRoutes, makeSchedulerUrls, SchedulerPaths } from "client/scheduler/routes";
import {
  useSubscriptionRoutes,
  makeSubscriptionUrls,
  SubscriptionPaths,
} from "client/subscriptions/routes";
import { useSupportRoutes, makeSupportUrls, SupportPaths } from "client/support/routes";
import { HomePaths, useHomeRoutes, makeHomeUrls } from "client/home/routes";
import {
  CommunicationPaths,
  useCommunicationRoutes,
  makeCommunicationUrls,
} from "client/communications/routes";

export type Paths = {
  accounts: AccountPaths;
  checkout: CheckoutPaths;
  links: LinkPaths;
  onboarding: OnboardingPaths;
  orders: OrderPaths;
  prescriptions: PrescriptionPaths;
  products: ProductPaths;
  subscriptions: SubscriptionPaths;
  support: SupportPaths;
  communications: CommunicationPaths;
  scheduler: SchedulerPaths;
  home: HomePaths;
};

const basePath = makeBasePath("/pet");

export const useClientRoutes = (opts: MakeRoutesOpts & { MainComponent: ComponentType }) => {
  const routes = [
    { path: "account", children: useAccountRoutes(opts) },
    { path: "checkout", children: useCheckoutRoutes(opts) },
    { path: "links", children: useLinkRoutes(opts) },
    { path: "onboarding", children: useOnboardingRoutes(opts) },
    { path: "orders", children: useOrderRoutes(opts) },
    { path: "prescriptions", children: usePrescriptionRoutes(opts) },
    { path: "products", children: useProductRoutes(opts) },
    { path: "subscriptions", children: useSubscriptionRoutes(opts) },
    { path: "support", children: useSupportRoutes(opts) },
    { path: "communications", children: useCommunicationRoutes(opts) },
    { path: "scheduler", children: useSchedulerRoutes(opts) },
    { path: "*", children: useHomeRoutes(opts) },
  ];

  return makeRoutes(opts, [
    eagerRoute({
      path: basePath,
      element: <opts.MainComponent />,
      children: makeRoutes({ ...opts, NotFoundComponent: null }, routes),
    }),
  ]);
};

export const urls = makeParentUrls(basePath, {
  accounts: makeAccountUrls(`${basePath}/account`),
  checkout: makeCheckoutUrls(`${basePath}/checkout`),
  links: makeLinkUrls(`${basePath}/links`),
  onboarding: makeOnboardingUrls(`${basePath}/onboarding`),
  orders: makeOrderUrls(`${basePath}/orders`),
  prescriptions: makePrescriptionUrls(`${basePath}/prescriptions`),
  products: makeProductUrls(`${basePath}/products`),
  subscriptions: makeSubscriptionUrls(`${basePath}/subscriptions`),
  support: makeSupportUrls(`${basePath}/support`),
  communications: makeCommunicationUrls(`${basePath}/communications`),
  scheduler: makeSchedulerUrls(`${basePath}/scheduler`),
  home: makeHomeUrls(`${basePath}`),
});

/**
 * Any links to external sites.
 *
 * Unlike regular urls, these are just strings because they are not managed by
 * the clinic app and need to rely directly on native browser navigation.
 */
export const externalUrls = {
  disposalOfUnusedMedicine: () =>
    "https://www.fda.gov/consumers/consumer-updates/where-and-how-dispose-unused-medicines",
  safeNeedleDisposal: () => "https://safeneedledisposal.org/",
  nabp: () => "https://nabp.pharmacy/",
  /**
   * Signs a client out of their current session
   * Signout is treated as an external URL because it is not managed by the FE and requires
   * a hard navigation.
   */
  signout: () => `${basePath}/signout/`,
};
