import { useCallback, useEffect, useMemo, useState } from "react";
import { v4 as uuidv4 } from "uuid";

import { replaceNth } from "common/utils/arrays";
import { useLoadStatus } from "common/load/hooks";
import { DynamicStackable } from "common/stackable/types";
import { annotateVisibleIndexes, partitionStackByType } from "common/stackable/utils";
import { STACKABLE_TRANSITION_DURATION_MS } from "common/constants/stackables";

import StackableContext, { StackableOpts } from "./StackableContext";
import CurrentStackableContext from "./CurrentStackableContext";

const noop = () => undefined;

type DrawerProviderProps = { children: React.ReactNode };

const markRemoved = (stackable: DynamicStackable) => {
  return { ...stackable, removedAt: new Date() };
};

const stackableIndex = (stack: DynamicStackable[], id: string) => {
  return stack.findIndex((s) => id === s.id);
};

const BREATHING_ROOM_OFFSET = 1.5; // Give extra time to animate in before calling the stackable positioned
const IS_POSITIONED_TIMEOUT_MS = STACKABLE_TRANSITION_DURATION_MS * BREATHING_ROOM_OFFSET;

type CurrentStackable = DynamicStackable & {
  visibleIndex: number | null;
  absoluteVisibleIndex: number | null;
};

type CurrentStackableProviderProps = {
  stackable: CurrentStackable;
  remove: (id: string) => void;
  index: number;
  totalVisible: number;
  requiresManualReload: boolean;
};

const CurrentStackableProvider = (props: CurrentStackableProviderProps) => {
  const { remove, stackable, totalVisible, requiresManualReload, index } = props;

  const [isPositioned, setIsPositioned] = useState(false);

  const value = useMemo(
    () => ({
      type: stackable.type,
      isVisible: !stackable.removedAt,
      remove: () => remove(stackable.id),
      isPositioned,
    }),
    [isPositioned, remove, stackable.id, stackable.removedAt, stackable.type],
  );

  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsPositioned(true);
    }, IS_POSITIONED_TIMEOUT_MS);

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  return (
    <CurrentStackableContext.Provider value={value}>
      {stackable.render({
        isVisible: !stackable.removedAt,
        visibleStackIndex: stackable.visibleIndex,
        stackIndex: index,
        isFocused: !!(
          !requiresManualReload &&
          stackable.absoluteVisibleIndex !== null &&
          stackable.absoluteVisibleIndex >= totalVisible - 1
        ),
        onClose: () => remove(stackable.id),
      })}
    </CurrentStackableContext.Provider>
  );
};

/**
 * Manages a stack of independently viewable items with an overlay
 * to pop the top item off the stack.
 */
const StackableProvider = ({ children }: DrawerProviderProps) => {
  const [stack, setStack] = useState<DynamicStackable[]>([]);
  const [onRemoveBuffer, setOnRemoveBuffer] = useState<{ id: string; onRemove: () => void }[]>([]);

  // a bit of a hack to get focus trapping to play nicely with our one-off
  // confirmation dialog for expired sessions. If we need multiple of these
  // we may want to think about abstracting the current "focused" element to it's
  // own context
  const { requiresManualReload } = useLoadStatus();

  const annotatedStack = annotateVisibleIndexes(stack).map(({ visibleIndex, ...rest }) => ({
    absoluteVisibleIndex: visibleIndex, // renamed to not be over-ridden by stack-local visible indexes after partitioning below
    ...rest,
  }));
  const totalVisible = annotatedStack.reduce((sum, current) => {
    return !current.removedAt ? sum + 1 : sum;
  }, 0);
  const stacksPartitionedByType = partitionStackByType(annotatedStack).map(annotateVisibleIndexes);

  /**
   * We buffer our side-effects here to later be called in a `useEffect` below.
   * We need to handle the setter possibly being called multiple times with the same stackable
   * in case the consumer calls `onRemove` multiple times or our queued state change is re-run.
   */
  const queueOnRemove = (...stackables: DynamicStackable[]) => {
    setOnRemoveBuffer((buffer) => {
      const currentIds = new Set(buffer.map((b) => b.id));
      const toAdd = stackables
        .filter((stackable) => stackable.onRemove && !currentIds.has(stackable.id))
        .map((stackable) => ({ id: stackable.id, onRemove: stackable.onRemove ?? noop }));
      if (!toAdd.length) {
        return buffer;
      }
      return [...buffer, ...toAdd];
    });
  };

  /*
  We need to call `onRemove` in a `useEffect` because we are setting removed state
  in a setter function which has similar semantics to render functions where we
  cannot perform side-effects.
  This also fixes https://github.com/facebook/react/issues/18178. (which incorrectly flags
  the setter as the render function for StackableProvider)
  */
  useEffect(() => {
    if (onRemoveBuffer.length) {
      onRemoveBuffer.forEach((b) => b.onRemove());
      setOnRemoveBuffer([]);
    }
  }, [onRemoveBuffer]);

  // memoize stackableContextValue to avoid re-renders of all consumers of StackableContext for each
  // provider re-render
  const stackableContextValue = useMemo(() => {
    return {
      push: ({ type, render, onRemove }: StackableOpts) => {
        setStack((current) => [
          // also garbage collect any previously removed stackables
          ...current.filter((s) => !s.removedAt),
          {
            type,
            render,
            id: uuidv4(),
            removedAt: null,
            onRemove,
          },
        ]);
      },
      removeAll: (type?: string) => {
        setStack((current) => {
          const shouldRemove = (stackable: DynamicStackable) =>
            (type === undefined || type === stackable.type) && !stackable.removedAt;
          // We reverse the order here so that `onRemove` is called in the reverse-order of
          // the stack, matching what consumers would most-likely expect.
          queueOnRemove(...current.filter(shouldRemove).reverse());

          return current.map((stackable) =>
            shouldRemove(stackable) ? markRemoved(stackable) : stackable,
          );
        });
      },
    };
  }, []);

  const remove = useCallback((id: string) => {
    // Set visible to false for the drawer to keep it mounted so the exit animation can play
    setStack((current) => {
      const i = stackableIndex(current, id);
      if (i === -1 || current[i].removedAt) {
        return current;
      }
      const stackable = current[i];
      queueOnRemove(stackable);
      return replaceNth(current, i, markRemoved(stackable));
    });
  }, []);

  return (
    <StackableContext.Provider value={stackableContextValue}>
      {children}
      {stacksPartitionedByType.flatMap((typeStack) =>
        typeStack.map((stackable, index) => (
          <CurrentStackableProvider
            key={stackable.id}
            stackable={stackable}
            remove={remove}
            index={index}
            totalVisible={totalVisible}
            requiresManualReload={requiresManualReload}
          />
        )),
      )}
    </StackableContext.Provider>
  );
};

export default StackableProvider;
