import {
  BasicCategory,
  FrequencyUnit,
  IItemAndFlavorOptionsWithProduct,
  IItemWithProduct,
  IPatient,
  UserSubscriptionAction,
  IPaymentMethod,
  IAddress,
  UISubscriptionStatus,
  FlavorSelection,
  BasicCatalogImage,
} from "common/types";
import { Optional } from "common/utils/types";
import { Incentive } from "common/types/promotions";
import { ISimpleOrder } from "common/types/orders";

import { ModalStatus } from "client/common/types";
import { OnboardingSubscriptionCancelReason } from "client/onboarding/types";

interface ISubscriptionFrequencyOption {
  frequency: number;
  interval_unit: FrequencyUnit;
  display: string;
  next_order_dates: string[];
}

/** Matches subscriptions.subscription_pricing_controller SubscriptionDiscountBreakdown  */
export interface SubscriptionDiscountBreakdown {
  display: string;
  discount: number;
  incentive: Incentive;
  rebase: never;
}

/** Matches subscriptions.subscription_pricing_controller SubscriptionPricing */
export interface SubscriptionPricing {
  unit_price: number;
  quantity: number;
  subtotal: number;
  discount: number;
  handling_fee: number;
  client_fees: number;
  shipping: number | null;
  discount_breakdown: SubscriptionDiscountBreakdown[];
  total: number;
}

/** matches subscriptions ClientSubscriptionBaseSerializer */
export interface ClientSubscription {
  id: number;
  item: IItemWithProduct;
  patient: IPatient;
  payment_method: Optional<IPaymentMethod>;
  shipping_address: IAddress;
  created: string;
  potential_user_actions: [UserSubscriptionAction, string][];
  last_order?: ISimpleOrder;
  active_next_order_date?: string;
  selections: FlavorSelection[];
  flavor_selection: number | null;
  flavors_display: Optional<string>;
  prescription_warning: Optional<string>;
  skip_order_date: string;
  active_frequency: ISubscriptionFrequencyOption;
  recommended_frequency: ISubscriptionFrequencyOption;
  alternative_frequencies: ISubscriptionFrequencyOption[];
  is_active_frequency_valid: boolean;
  visible_status: UISubscriptionStatus;
  visible_status_display: string;
  has_prescription: Optional<boolean>;
  next_order_data: SubscriptionPricing;
  display_number: string;
  action: UserSubscriptionAction;
  interval_unit: FrequencyUnit;
  frequency: number;
  next_order_date: string;
  quantity: number;
  prescription_needs_renewal_for_next_order: boolean;
  should_show_employee_pricing_note: boolean;
}

/** matches subscriptions ClientOnboardingSubscriptionListSerializer */
export interface OnboardingDetailSubscription {
  id: number;
  item: IItemAndFlavorOptionsWithProduct;
  patient: IPatient;
  flavor_selection: number | null;
  active_frequency: ISubscriptionFrequencyOption;
  recommended_frequency: ISubscriptionFrequencyOption;
  alternative_frequencies: ISubscriptionFrequencyOption[];
  is_active_frequency_valid: boolean;
  quantity: number;
}

export type SubscriptionListSearch = {
  visible_status?: UISubscriptionStatus[];
};

/** Matches subscriptions ClientSubscriptionDetailSerializer */
export interface DetailSubscription extends ClientSubscription {}

/** Matches writable data in ClientSubscriptionDetailSerializer */
export interface SubscriptionUpdate {
  action: UserSubscriptionAction;
  interval_unit?: FrequencyUnit;
  frequency?: number;
  next_order_date?: string;
  quantity?: number;
  shipping_address_id?: number;
  flavor_selection?: number;
  cancel_reason?: OnboardingSubscriptionCancelReason;
  cancel_reason_other?: string;
}

/** Matches expected data in ClientSubscriptionPaymentView */
export interface SubscriptionPaymentUpdate {
  payment_method_id: number;
}

export enum SubscriptionForm {
  UpdateQuantity = "updateQuantity",
  UpdateFrequency = "updateFrequency",
  UpdateAddress = "updateAddress",
  UpdatePayment = "updatePayment",
  UpdateNextOrder = "updateNextOrder",
  SkipNextOrder = "skipNextOrder",
  Pause = "pause",
  Cancel = "cancel",
  Resume = "resume",
}

export type SubscriptionFormProps = {
  subscription: ClientSubscription;
  visible: boolean;
  onClose: () => void;
};

export type SubscriptionSearch = {
  form?: SubscriptionForm;
  visible?: ModalStatus;
};

export type BulkUpdateSubscriptions = {
  subscription_ids: number[];
  shipping_address_id?: number;
  payment_method_id?: number;
};

export type BulkUpdateSubscriptionsResponse = {
  updated_subscription_count: number;
};

/* Matches subscriptions ClientSubscriptionListProductLineSerializer */
export interface SubscriptionListProductLine {
  id: number;
  name: string;
  short_description: Optional<string>;
  prescription_required: boolean;
  categories: BasicCategory[];
}

/* Matches subscriptions ClientSubscriptionListProductSerializer */
export interface SubscriptionListProduct {
  id: number;
  name: string;
  product_line: SubscriptionListProductLine;
}

/* Matches subscriptions ClientSubscriptionListItemSerializer */
export interface SubscriptionListItem {
  id: number;
  name: string;
  msrp: number;
  full_name: string;
  image: Optional<BasicCatalogImage>;
  product: SubscriptionListProduct;
}

/* Matches subscriptions ClientSubscriptionListPatientSerializer */
export interface SubscriptionListPatient {
  id: number;
  name_display: string;
}

/* Matches subscriptions ClientSubscriptionListSubscriptionSerializer.next_order_data */
export interface SubscriptionListNextOrderData {
  unit_price: number;
  discount: number;
  subtotal: number;
}

/* Matches subscriptions ClientSubscriptionListSerializer */
export interface ListSubscription {
  id: number;
  item: SubscriptionListItem;
  patient: SubscriptionListPatient;
  payment_method: Optional<IPaymentMethod>;
  shipping_address: IAddress;
  potential_user_actions: [UserSubscriptionAction, string][];
  last_order?: ISimpleOrder;
  flavor_selection: number | null;
  flavors_display: Optional<string>;
  active_frequency: ISubscriptionFrequencyOption;
  recommended_frequency: ISubscriptionFrequencyOption;
  alternative_frequencies: ISubscriptionFrequencyOption[];
  is_active_frequency_valid: boolean;
  visible_status: UISubscriptionStatus;
  next_order_data: SubscriptionListNextOrderData;
  next_order_date: string;
  quantity: number;
  prescription_needs_renewal_for_next_order: boolean;
  has_prescription: Optional<boolean>;
}
